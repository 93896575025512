import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  Typography,
  Grid
} from '@material-ui/core'

function PropertyInfo (props) {
  const {
    errors,
    onChange,
    ownerInfo
  } = props
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h5" gutterBottom>
          Owner
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="fname"
          name="owner.firstName"
          variant="outlined"
          margin="dense"
          fullWidth
          id="owner.firstName"
          label="First Name"
          onChange={onChange}
          value={ownerInfo.firstName || ''}
          error={errors.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="owner.lastName"
          label="Last Name"
          name="owner.lastName"
          onChange={onChange}
          value={ownerInfo.lastName || ''}
          error={errors.lastName}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="owner.email"
          label="Email Address"
          name="owner.email"
          onChange={onChange}
          value={ownerInfo.email || ''}
          error={errors.email}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="ownerStreet"
          label="Street"
          name="owner.address.street"
          onChange={onChange}
          value={ownerInfo.address.street || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth
          id="ownerCity"
          label="City"
          name="owner.address.city"
          onChange={onChange}
          value={ownerInfo.address.city || ''}
          error={errors.email}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="ownerState"
          label="State"
          name="owner.address.state"
          onChange={onChange}
          value={ownerInfo.address.state || ''}
          error={errors.email}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="ownerZipCode"
          label="Zipcode"
          name="owner.address.zipCode"
          onChange={onChange}
          value={ownerInfo.address.zipCode || ''}
          error={errors.email}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="ownerCellPhone"
          label="Cell Phone"
          name="owner.cellPhone"
          onChange={onChange}
          value={ownerInfo.cellPhone || ''}
          error={errors.email}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="ownerHomePhone"
          label="Home Phone"
          name="owner.homePhone"
          onChange={onChange}
          value={ownerInfo.homePhone || ''}
          error={errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="ownerOfficePhone"
          label="Office Phone"
          name="owner.officePhone"
          onChange={onChange}
          value={ownerInfo.officePhone || ''}
          error={errors.email}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="ownerOtherPhone"
          label="Other Phone"
          name="owner.otherPhone"
          onChange={onChange}
          value={ownerInfo.otherPhone || ''}
          error={errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          id="ownerAlarmCode"
          label="Alarm Code"
          name="owner.alarmCode"
          onChange={onChange}
          value={ownerInfo.alarmCode || ''}
          error={errors.email}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          multiline
          rows={3}
          rowsMax={6}
          id="ownerAdditional"
          label="Additional"
          name="owner.additional"
          onChange={onChange}
          value={ownerInfo.additional || ''}
          error={errors.email}
        />
      </Grid>
    </Grid>
  )
}

PropertyInfo.propTypes = {
  errors: PropTypes.object.isRequired,
  ownerInfo: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

export default PropertyInfo
