import axios from 'axios'
import {
  GET_ERRORS,
  SET_PROPERTIES,
  ADD_PROPERTY,
  DELETE_PROPERTY,
  UPDATE_PROPERTY,
  SEND_CONTRACT_SUCCESS,
  LOADING,
  DISABLE_ALERT
} from './types'
// Register User

function cleanCostString (costString) {
  if (typeof costString === 'string') {
    const cleanedCost = costString.replace(/[^0-9.-]+/g, '')
    const costNumber = cleanedCost.length > 0 ? parseFloat(cleanedCost) : 0
    return costNumber
  } else {
    return costString
  }
}

export const addNewProperty = (propertyData, history) => dispatch => {
  // clean cost strings for formatting
  propertyData.currentOwed = cleanCostString(propertyData.currentOwed)
  propertyData.price = cleanCostString(propertyData.price)
  propertyData.additionalCosts = cleanCostString(propertyData.additionalCosts)
  propertyData.contractCost = cleanCostString(propertyData.contractCost)
  propertyData.owner.email = propertyData.owner.email.trim()
  axios
    .post('/api/properties/add/', propertyData)
    .then(res => {
      dispatch(addProperty(propertyData))
      history.push('/dashboard')
    })
    .catch(err => {
      console.error(err)
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}
// Get properties - load properties from server for this user
export const getProperties = userData => dispatch => {
  dispatch(setLoading(true))
  axios
    .get('/api/properties/' + userData)
    .then(res => {
      const properties = res.data
      dispatch(setProperties(properties))
      for (const prop in properties) {
        const updatedProp = properties[prop]
        if (updatedProp.location.lon === '-1' && updatedProp.location.lat === '-1') {
          axios
            .get(`https://nominatim.openstreetmap.org/search?q=${updatedProp.location.street},${updatedProp.location.city},${updatedProp.location.state}+${updatedProp.location.zipCode}+USA&format=json&limit=1`)
            .then(res => {
              const { lat, lon } = res.data[0]
              updatedProp.location.lat = lat
              updatedProp.location.lon = lon
              axios
                .put('/api/properties/update/' + userData, updatedProp)
                .then(res => {
                  dispatch(updateProperty(updatedProp))
                })
                .catch(err => console.error('ERRORS: ' + err)
                )
            })
            .catch(err => {
              console.error("Couldn't retrieve information:", err, res)
            })
        }
      }
      dispatch(setLoading(false))
    })
    .catch(err => {
      console.log('Error, get properties failed with: ' + err)
      dispatch(setLoading(false))
    })
}

export const deleteExistingProperty = (propertyId, userData, history) => dispatch => {
  axios
    .delete('/api/properties/delete/' + propertyId)
    .then(res => {
      dispatch(deleteProperty(propertyId))
      history.push('/dashboard')
    })
    .catch(err => console.log('ERRORS: ' + err)
    )
}

export const deleteExistingPropertyEvent = (eventId, propertyId) => dispatch => {
  axios
    .delete('/api/properties/events/' + propertyId + '/delete/' + eventId)
    .then(res => {
      return res
    })
    .catch(err => console.log('ERRORS: ' + err)
    )
}

export const sendPropertyEvent = (event, propertyId, addPropertyEventLocal, handleCloseEvent, clearData) => dispatch => {
  event.amount = cleanCostString(event.amount).toString()
  axios
    .post('/api/properties/events/' + propertyId + '/create/', event)
    .then(res => {
      addPropertyEventLocal(event)
      handleCloseEvent()
      clearData()
      return res
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
      return err
    })
}

export const updateExistingProperty = (propertyData, userData, history) => dispatch => {
  propertyData.location.lat = '-1'
  propertyData.location.lon = '-1'
  // clean cost strings for formatting
  propertyData.currentOwed = cleanCostString(propertyData.currentOwed)
  propertyData.price = cleanCostString(propertyData.price)
  propertyData.additionalCosts = cleanCostString(propertyData.additionalCosts)
  propertyData.contractCost = cleanCostString(propertyData.contractCost)
  propertyData.owner.email = propertyData.owner.email.trim()
  axios
    .put('/api/properties/update/' + userData, propertyData)
    .then(res => {
      dispatch(updateProperty(propertyData))
      history.push('/property/' + propertyData._id)
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

export const updateExistingPropertyRedirectHome = (propertyData, userData, history) => dispatch => {
  axios
    .put('/api/properties/update/' + userData, propertyData)
    .then(res => {
      dispatch(updateProperty(propertyData))
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

export const sendPropertyContract = (propertyData, userData, history) => dispatch => {
  dispatch(setLoading(true))
  axios
    .post('/api/mail/contract/', { userData: userData, propertyData: propertyData })
    .then(res => {
      console.log('Success: ' + res.data.message)
      dispatch(setLoading(false))
      dispatch(setSendContractSuccess(true))
    })
    .catch(err => {
      console.log(`Error: ${err.message}`)
      dispatch(setLoading(false))
      dispatch(setSendContractSuccess(false))
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

export const signPropertyContract = (signature, history) => dispatch => {
  axios
    .post('/api/properties/sign/', signature)
    .then(res => {
      history.push('/')
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

export const disableContractAlert = () => dispatch => {
  dispatch({
    type: DISABLE_ALERT,
    payload: null
  })
}

export const downgradeUserMembership = () => dispatch => {
  dispatch(setLoading(true))
  axios
    .delete('/stripehook/subscription')
    .then(res => {
      console.log(res)
      dispatch(setLoading(false))
    })
    .catch(err => {
      console.log(err)
      console.error('Unable to downgrade subscription: ' + err)
      dispatch(setLoading(false))
    })
}

/*
 * DISPATCHERS FOR UPDATING REDUX
 * ==============================
 */

// set properties
export const setProperties = properties => {
  return {
    type: SET_PROPERTIES,
    payload: properties
  }
}

// delete property
export const deleteProperty = property => {
  return {
    type: DELETE_PROPERTY,
    payload: property._id
  }
}

// Add a property
export const addProperty = property => {
  return {
    type: ADD_PROPERTY,
    payload: property
  }
}

// update a property
export const updateProperty = property => {
  return {
    type: UPDATE_PROPERTY,
    payload: property
  }
}

// set loading circle
export const setLoading = isLoading => {
  return {
    type: LOADING,
    payload: isLoading
  }
}

// set contract success/failure alert
export const setSendContractSuccess = success => {
  return {
    type: SEND_CONTRACT_SUCCESS,
    payload: success
  }
}

/*
 * ==============================
 */
