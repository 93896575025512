import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  Typography,
  Grid
} from '@material-ui/core'

function RepresentativeInfo (props) {
  const {
    errors,
    onChange,
    repInfo
  } = props
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h5" gutterBottom>
          Representative to Notify
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
            variant="outlined"
            fullWidth
            id="ownerRepToNotify"
            margin="dense"
            label="Name"
            name="owner.repToNotify"
            onChange={onChange}
            value={repInfo.repToNotify || ''}
            error={errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
      <Grid item xs={12} sm={12}>
        <TextField
            variant="outlined"
            fullWidth
            id="ownerRepStreet"
            margin="dense"
            label="Street"
            name="owner.repAddress.street"
            onChange={onChange}
            value={repInfo.repAddress.street || ''}
            error={errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
            variant="outlined"
            fullWidth
            id="ownerRepCity"
            margin="dense"
            label="City"
            name="owner.repAddress.city"
            onChange={onChange}
            value={repInfo.repAddress.city || ''}
            error={errors.email}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
            variant="outlined"
            fullWidth
            margin="dense"
            id="ownerRepState"
            label="State"
            name="owner.repAddress.state"
            onChange={onChange}
            value={repInfo.repAddress.state || ''}
            error={errors.email}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
            variant="outlined"
            fullWidth
            id="ownerownerRepZipCode"
            margin="dense"
            label="Zipcode"
            name="owner.repAddress.zipCode"
            onChange={onChange}
            value={repInfo.repAddress.zipCode || ''}
            error={errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
            variant="outlined"
            fullWidth
            id="ownerRepPhone"
            label="Phone"
            margin="dense"
            name="owner.repPhone"
            onChange={onChange}
            value={repInfo.repPhone || ''}
            error={errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
            variant="outlined"
            fullWidth
            margin="dense"
            id="ownerRepSecondPhone"
            label="Alternate Phone"
            name="owner.repSecondPhone"
            onChange={onChange}
            value={repInfo.repSecondPhone || ''}
            error={errors.email}
        />
      </Grid>
    </Grid>
  )
}

RepresentativeInfo.propTypes = {
  errors: PropTypes.object.isRequired,
  repInfo: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

export default RepresentativeInfo
