import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  Paper,
  Container,
  Typography,
  Grid,
  Fab
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { withStyles } from '@material-ui/core/styles'
import './css/React.css'

const styles = theme => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
})

class Cancelled extends Component {
  constructor (props) {
    super(props)
    this.state = {
      clientSecret: '',
      errors: {}
    }
  }

  render () {
    const { classes } = this.props
    return (
      <Container component="main" maxWidth="lg" spacing={0}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Cancelled | CaretakerDB</title>
          <link rel="canonical" href="https://caretakerdb.com/cancelled" />
        </Helmet>
        <Paper className={classes.paper}>
          <Grid container spacing={5} alignItems="flex-end">
            <Grid item xs={12}>
              <Typography variant="h2" component="h2">Cancelled</Typography>
              <Typography variant="body2" component="p" color="textSecondary">Your payment was unsuccessful :(</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" color="secondary">Hm, it looks like you canceled or clicked away from the payment page. If this was an error please click <Link to="/membership">here</Link> and select your choice again!</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" color="secondary">You have not been charged for anything and have not been submitted for an upgraded account. If you&apos;re having issues please go to the <Link to="/contact">Contact</Link> page and explain the issue you&apos;re having.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" color="secondary">If you canceled on purpose you&apos;re free to keep using your account as-is. Please use the button below to return to the dashboard or use the menu as normal.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Fab component={Link} to="/dashboard/" color="primary">
                <ArrowBackIcon />
              </Fab>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    )
  }
}
Cancelled.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth
})
export default connect(
  mapStateToProps
)(withStyles(styles)(Cancelled))
