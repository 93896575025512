import {
  DISABLE_ALERT,
  LOADING,
  SEND_CONTRACT_SUCCESS
} from '../actions/types'
const initialState = {
  loading: false,
  sendContractSuccess: false,
  sendContractIsNew: false
}
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case SEND_CONTRACT_SUCCESS:
      return {
        ...state,
        sendContractSuccess: action.payload,
        sendContractIsNew: true
      }
    case DISABLE_ALERT:
      return {
        ...state,
        sendContractIsNew: false
      }
    default:
      return state
  }
}
