import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Dialog,
  DialogTitle,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'

class PropertyEventOptions extends Component {
  render () {
    const { menuOpen, handleCloseDelete } = this.props
    return (
      <Dialog onClose={this.props.handleClose} aria-labelledby="simple-dialog-title" open={menuOpen}>
        <DialogTitle id="simple-dialog-title">
          Property Event Options
          <IconButton aria-label="close" onClick={this.props.handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <List>
          <ListItem button onClick={handleCloseDelete}>
            <ListItemAvatar>
              <Avatar>
                <DeleteIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Delete" />
          </ListItem>
        </List>
      </Dialog>
    )
  }
}
PropertyEventOptions.propTypes = {
  handleCloseDelete: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  menuOpen: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  properties: state.properties
})
export default connect(
  mapStateToProps
)(PropertyEventOptions)
