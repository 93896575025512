import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core'

function LimitDialog () {
  const [open, setOpen] = useState(false)

  function handleClose () {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Want to upgrade?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Unfortunately, you&apos;ve hit the property limit for this payment tier. If you&apos;d like to be able to add more properties,
          you will need to upgrade your account. You can do this through the <b>Profile</b> link in the top right or you can
          click on the <b>Upgrade</b> button below!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Go back
        </Button>
        <Button to="/membership" color="primary" component={Link} autoFocus>
          Upgrade
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LimitDialog
