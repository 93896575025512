import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { logoutUser } from '../../../../actions/authActions'
import { addNewProperty, getProperties } from '../../../../actions/propertyActions'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import { Helmet } from 'react-helmet'
import SaveIcon from '@material-ui/icons/Save'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import {
  LimitDialog,
  PropertyInfo,
  OwnerInfo,
  RepresentativeInfo,
  Services,
  Special
} from '../components'
import {
  Container,
  Button,
  Typography,
  Divider,
  Grid,
  Chip,
  CssBaseline,
  Fab
} from '@material-ui/core'

moment.locale('en')

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
})

class NewProperty extends Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: false,
      property: {
        userId: this.props.auth.user.id,
        season: '',
        price: 0,
        additionalCosts: 0,
        additionalCostsDetails: '',
        currentOwed: 0,
        dateCreated: Date.now(),
        contractStartDate: null,
        contractEndDate: null,
        contractCost: 0,
        paid: false,
        location: {
          street: '',
          city: '',
          zipCode: '',
          state: '',
          lat: '-1',
          lon: '-1'
        },
        owner: {
          firstName: '',
          lastName: '',
          plowing: false,
          email: '',
          address: {
            street: '',
            city: '',
            zipCode: '',
            state: ''
          },
          homePhone: '',
          officePhone: '',
          otherPhone: '',
          cellPhone: '',
          repToNotify: '',
          repAddress: {
            street: '',
            city: '',
            zipCode: '',
            state: ''
          },
          repPhone: '',
          repSecondPhone: '',
          alarmCode: '',
          additional: ''
        },
        services: {
          irrigation: {
            contact: '',
            phone: ''
          },
          plumber: {
            contact: '',
            phone: ''
          },
          electrician: {
            contact: '',
            phone: ''
          },
          carpenter: {
            contact: '',
            phone: ''
          },
          appliance: {
            contact: '',
            phone: ''
          },
          furnace: {
            contact: '',
            phone: ''
          },
          cleaner: {
            contact: '',
            phone: ''
          },
          boatsAndDocks: {
            contact: '',
            phone: ''
          }
        },
        special: {
          outsideShower: false,
          outsideFaucet: false,
          outsideSpa: false,
          other: ''
        },
        terms: {
          date: Date.now(),
          signed: ''
        }
      },
      errors: {}
    }
    this.onChange = this.onChange.bind(this)
    this.onChangeDate = this.onChangeDate.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChange (event) {
    if (!event) {
      return
    }
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const vals = name.split('.')
    if (vals.length === 1) {
      if (vals[0] === 'contractStartDate' || vals[0] === 'contractEndDate') {
        this.setState(prevState => ({
          ...prevState,
          property: {
            ...prevState.property,
            [vals[0]]: moment(value)
          }
        }))
      } else {
        this.setState(prevState => ({
          ...prevState,
          property: {
            ...prevState.property,
            [vals[0]]: value
          }
        }))
      }
    } else if (vals.length === 2) {
      this.setState(prevState => ({
        ...prevState,
        property: {
          ...prevState.property,
          [vals[0]]: {
            ...prevState.property[vals[0]],
            [vals[1]]: value
          }
        }
      }))
    } else if (vals.length === 3) {
      this.setState(prevState => ({
        ...prevState,
        property: {
          ...prevState.property,
          [vals[0]]: {
            ...prevState.property[vals[0]],
            [vals[1]]: {
              ...prevState.property[vals[0]][vals[1]],
              [vals[2]]: value
            }
          }
        }
      }))
    }
  }

  onChangeDate (date, dateName) {
    if (date && dateName) {
      this.setState(prevState => ({
        ...prevState,
        property: {
          ...prevState.property,
          [dateName]: date
        }
      }))
    }
  }

  componentDidMount () {
    if (!this.props.properties.properties.length) {
      this.props.getProperties(this.props.auth.user.id)
    }
  }

  onSubmit (e) {
    e.preventDefault()
    this.props.addNewProperty(this.state.property, this.props.history)
  }

  componentDidUpdate (prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({
        errors: this.props.errors
      })
    }
  }

  render () {
    const { errors } = this.state
    const { classes } = this.props
    const sharedProps = {
      onChange: this.onChange,
      onChangeDate: this.onChangeDate,
      errors: errors
    }

    return (
        <Container fixed>
          <Helmet>
            <meta charSet="utf-8" />
            <title>New Property | CaretakerDB</title>
            <link rel="canonical" href="https://caretakerdb.com/new-property" />
          </Helmet>
          {this.state.errors.limit && <LimitDialog open={true} errors={this.state.errors}/>}
            <Grid
              container
              direction="row"
              spacing={3}
              style={{ minHeight: '70vh' }}>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  New property &nbsp;
                  {errors.limit
                    ? <Chip
                    icon={<NewReleasesIcon />}
                    label={errors.limit}
                    color="primary"
                    />
                    : <div></div>}
                </Typography>
                <Divider width="100%" />
              </Grid>
              <Grid item xs={12}>
              <CssBaseline />
              <div className={classes.paper}>
              <form className={classes.form} onSubmit={this.onSubmit} noValidate>
                <PropertyInfo propertyInfo={this.state.property} {...sharedProps} />
                <OwnerInfo ownerInfo={this.state.property.owner} {...sharedProps} />
                <RepresentativeInfo repInfo={this.state.property.owner} {...sharedProps} />
                <Services servicesInfo={this.state.property.services} {...sharedProps} />
                <Special specialInfo={this.state.property.special} {...sharedProps} />
                <Grid container spacing={2} justify="flex-start">
                  <Grid item xs={12} s={6} lg={3}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Add property
                    </Button>
                  </Grid>
                </Grid>
                <Fab aria-label="Save" style={{ position: 'fixed', right: 50, bottom: 50 }} color="primary" type="submit">
                  <SaveIcon />
                </Fab>
              </form>
            </div>
          </Grid>
        </Grid>
    </Container>
    )
  }
}
NewProperty.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  addNewProperty: PropTypes.func.isRequired,
  getProperties: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  properties: state.properties,
  errors: state.errors
})
export default connect(
  mapStateToProps,
  { logoutUser, addNewProperty, getProperties }
)(withSnackbar(withStyles(styles)(NewProperty)))
