import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import { withStyles } from '@material-ui/core/styles'
import {
  Typography,
  Grid
} from '@material-ui/core'

import Container from '@material-ui/core/Container'

const styles = theme => ({

})

class About extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <Container maxWidth="md">
        <Helmet>
          <meta charSet="utf-8" />
          <title>About | CaretakerDB</title>
          <link rel="canonical" href="https://caretakerdb.com/about" />
          <meta name="description" content="CaretakerDB about page, who what where and how of all CaretakerDB." />
        </Helmet>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" color="secondary" gutterBottom>About</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" color="secondary">Who?</Typography>
            <Typography variant="body1" color="secondary">Founded by me (Gavin) and is a sole ownership with 100% commitment to delivering the best and easiest possible experience. I built this with one idea in mind; make caretakers&apos; lives easier!</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" color="secondary">What?</Typography>
            <Typography variant="body1" color="secondary">CaretakerDB is an all-in-one solution for caretakers who are ready to move on from paper or outdated software and simplify their lives.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" color="secondary">Why?</Typography>
            <Typography variant="body1" color="secondary">Originally built to solve the problems of a single person, we quickly realized that we could save caretakers take and money by removing the most teidious part of caretaking: the paperwork. CaretakerDB aims to help you focus on your work rather than the things that distract you, <Link to="/register">give it a shot now!</Link></Typography>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

About.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})
export default connect(
  mapStateToProps
)(withRouter(withStyles(styles)(About)))
