import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import setAuthToken from './utils/setAuthToken'
import { setCurrentUser, logoutUser } from './actions/authActions'

import { Provider } from 'react-redux'
import store from './store'

import { SnackbarProvider } from 'notistack'
import PropTypes from 'prop-types'

import Navbar from './components/layout/Navbar'
import MobileNavbar from './components/layout/MobileNavbar'
import UpdateLoader from './components/layout/UpdateLoader'
import StickyFooter from './components/layout/Footer'
import Landing from './components/layout/Landing'
import Register from './components/auth/Register'
import Login from './components/auth/Login'
import PrivateRoute from './components/private-route/PrivateRoute'
import Dashboard from './components/dashboard/Dashboard'
import NewProperty from './components/dashboard/NewProperty'
import PropertyView from './components/dashboard/PropertyView'
import Print from './components/dashboard/Print'
import EditView from './components/dashboard/EditView'
import About from './components/public/About'
import Contact from './components/public/Contact'
import Forgot from './components/public/Forgot'
import Sign from './components/public/Sign'
import Profile from './components/account/Profile'
import Success from './components/payment/Success'
import Cancelled from './components/payment/Cancelled'
import Membership from './components/payment/Membership'
import red from '@material-ui/core/colors/red'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'
import { MuiThemeProvider, responsiveFontSizes } from '@material-ui/core'

import { Helmet } from 'react-helmet'
import ResetPassword from './components/auth/ResetPassword'
import ResetSuccess from './components/auth/ResetSuccess'
import UniqueReset from './components/auth/UniqueReset'
import SendContractSuccess from './components/layout/SendContractSuccess'

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#009688'
    },
    secondary: {
      main: '#263238'
    },
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  }
})

theme = responsiveFontSizes(theme)

const drawerWidth = 240

const styles = theme => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  growDrawer: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  grow: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    marginLeft: drawerWidth
  },
  toolbar: theme.mixins.toolbar
})

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken
  setAuthToken(token)
  // Decode token and get user info and exp
  const decoded = jwt_decode(token)
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded))
  // Check for expired token
  const currentTime = Date.now() / 1000 // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser())
    // Redirect to login
    window.location.href = './login'
  }
}

class App extends Component {
  constructor (props) {
    super(props)
    this.state = { windowWidth: window.innerWidth }
    this.handleResize = this.handleResize.bind(this)
  }

  handleResize (e) {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnMount () {
    window.addEventListener('resize', this.handleResize)
  }

  render () {
    const { classes } = this.props
    const { windowWidth } = this.state
    return (
      <Provider store={store}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Welcome | CaretakerDB</title>
          <link rel="canonical" href="https://caretakerdb.com/" />
          <meta name="description" content="CaretakerDB home page with information and access to managaement needs! " />
        </Helmet>
        <SnackbarProvider maxSnack={3}>
          <Router>
            <MuiThemeProvider theme={theme}>
              <main className={classes.root}>
                {windowWidth > 760
                  ? <Navbar />
                  : <MobileNavbar />}
                <div className={windowWidth > 760 ? classes.grow : classes.growDrawer}>
                  <div className={classes.toolbar} />
                  <Route exact path="/" component={Landing} />
                  <Route exact path="/register" component={Register} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/reset" component={ResetPassword} />
                  <Route exact path="/resets/:id" component={UniqueReset} />
                  <Route exact path="/reset-success" component={ResetSuccess} />
                  <Route exact path="/forgot" component={Forgot} />
                  <Route exact path="/about" component={About} />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/sign/:id" component={Sign} />
                  <Route exact path="/success" component={Success} />
                  <Route exact path="/cancelled" component={Cancelled} />
                  <Switch>
                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    <PrivateRoute path="/property/:id" component={PropertyView} />
                    <PrivateRoute path="/print/:id" component={Print} />
                    <PrivateRoute path="/edit/:id" component={EditView} />
                    <PrivateRoute exact path="/new-property" component={NewProperty} />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute exact path="/membership" component={Membership} />
                  </Switch>
                </div>
                <StickyFooter />
                <UpdateLoader />
                <SendContractSuccess />
              </main>
            </MuiThemeProvider>
          </Router>
        </SnackbarProvider>
      </Provider>
    )
  }
}
App.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(App)
