import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  Typography,
  Grid
} from '@material-ui/core'

function Services (props) {
  const {
    errors,
    onChange,
    servicesInfo
  } = props
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h5" gutterBottom>
          Services
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesIrrigationContact"
          label="Irrigation Company"
          name="services.irrigation.contact"
          onChange={onChange}
          value={servicesInfo.irrigation.contact || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesIrrigationPhone"
          label="Irrigation Phone"
          name="services.irrigation.phone"
          onChange={onChange}
          value={servicesInfo.irrigation.phone || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesPlumberContact"
          label="Plumbing Company"
          name="services.plumber.contact"
          onChange={onChange}
          value={servicesInfo.plumber.contact || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesPlumberPhone"
          label="Plumbing Phone"
          name="services.plumber.phone"
          onChange={onChange}
          value={servicesInfo.plumber.phone || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesElectricianContact"
          label="Electrician Company"
          name="services.electrician.contact"
          onChange={onChange}
          value={servicesInfo.electrician.contact || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesElectricianPhone"
          label="Electrician Phone"
          name="services.electrician.phone"
          onChange={onChange}
          value={servicesInfo.electrician.phone || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesCarpenterContact"
          label="Carpentry Company"
          name="services.carpenter.contact"
          onChange={onChange}
          value={servicesInfo.carpenter.contact || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesCarpenterPhone"
          label="Carpentry Phone"
          name="services.carpenter.phone"
          onChange={onChange}
          value={servicesInfo.carpenter.phone || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesApplianceContact"
          label="Appliance Company"
          name="services.appliance.contact"
          onChange={onChange}
          value={servicesInfo.appliance.contact || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesAppliancePhone"
          label="Appliance Phone"
          name="services.appliance.phone"
          onChange={onChange}
          value={servicesInfo.appliance.phone || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesFurnaceContact"
          label="Furnace/HVAC Company"
          name="services.furnace.contact"
          onChange={onChange}
          value={servicesInfo.furnace.contact || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesFurnacePhone"
          label="Furnace/HVAC Phone"
          name="services.furnace.phone"
          onChange={onChange}
          value={servicesInfo.furnace.phone || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesCleanerContact"
          label="Cleaning Company"
          name="services.cleaner.contact"
          onChange={onChange}
          value={servicesInfo.cleaner.contact || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesCleanerPhone"
          label="Cleaning Phone"
          name="services.cleaner.phone"
          onChange={onChange}
          value={servicesInfo.cleaner.phone || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesBoatsAndDocksContact"
          label="Boats/Docks Company"
          name="services.boatsAndDocks.contact"
          onChange={onChange}
          value={servicesInfo.boatsAndDocks.contact || ''}
          error={errors.companyName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="servicesBoatsAndDocksPhone"
          label="Boats/Docks Phone"
          name="services.boatsAndDocks.phone"
          onChange={onChange}
          value={servicesInfo.boatsAndDocks.phone || ''}
          error={errors.companyName}
        />
      </Grid>
    </Grid>
  )
}

Services.propTypes = {
  onChange: PropTypes.func.isRequired,
  servicesInfo: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

export default Services
