import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
  ListItemText,
  ListItemSecondaryAction,
  Fab,
  Grid,
  Paper
} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import EditIcon from '@material-ui/icons/Edit'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import BusinessIcon from '@material-ui/icons/Business'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LockIcon from '@material-ui/icons/Lock'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 768,
    minWidth: 345
  }
}))

export function Profile (props) {
  const { user } = props.auth
  const userJoinDate = new Date(user.date)
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
  const displayJoinDate = new Intl.DateTimeFormat('en-US', options).format(userJoinDate)
  const classes = useStyles()
  const memberDate = new Intl.DateTimeFormat('en-US', options).format(new Date(user.membershipPayDate))
  return (
      <Grid container direction="row" justify="center">
        <Paper className={classes.root}>
          <List subheader={<ListSubheader>Account</ListSubheader>}>
            <ListItem>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={`${user.firstName} ${user.lastName}`} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primary={user.email} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary={user.companyName} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <VerifiedUserIcon />
              </ListItemIcon>
              <ListItemText primary={user.membership} secondary={user.membershipId ? <>Renewal on {user.membershipPayDate === null ? 'not a member.' : `${memberDate.getUTCMonth() + 1}/${memberDate.getUTCDate()}/${memberDate.getUTCFullYear()}`} <Link to='/membership'>Modify?</Link></> : <Link to='/membership'>Modify?</Link>} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              <ListItemText primary={user.passwordResetDate ? new Intl.DateTimeFormat('en-US', options).format(new Date(user.passwordResetDate)) : 'No password reset'} secondary="Last password reset date" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FavoriteIcon />
              </ListItemIcon>
              <ListItemText primary={`Member since ${userJoinDate ? displayJoinDate : '(Today?)'}`} />
            </ListItem>
            <ListItem>
              <ListItemSecondaryAction>
                <Fab color="secondary" aria-label="edit" disabled>
                  <EditIcon />
                </Fab>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>
      </Grid>
  )
}
Profile.propTypes = {
  auth: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth
})
export default connect(
  mapStateToProps
)(Profile)
