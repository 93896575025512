import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  withStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import PropTypes from 'prop-types'
import MomentUtils from '@date-io/moment'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

import { sendPropertyEvent } from '../../../actions/propertyActions'

const styles = theme => ({

})

class PropertyEventModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      amount: '',
      eventType: '1',
      eventDate: moment(),
      notes: '',
      errors: {}
    }

    this.onChange = this.onChange.bind(this)
    this.onChangeDate = this.onChangeDate.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.clearData = this.clearData.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({
        errors: this.props.errors
      })
    }
  }

  onChange (e) {
    if (e.target.type === 'radio') {
      this.setState({ [e.target.name]: e.target.value })
    } else {
      this.setState({ [e.target.id]: e.target.value })
    }
  };

  onChangeDate (date, dateName) {
    if (date && dateName) {
      this.setState({
        [dateName]: date
      })
    }
  };

  clearData () {
    this.setState({
      amount: '',
      eventType: '0',
      eventDate: moment(),
      notes: ''
    })
  }

  onSubmit (e) {
    e.preventDefault()
    const newEvent = {
      propertyId: this.props.propertyId,
      amount: this.state.amount,
      eventType: this.state.eventType,
      eventDate: this.state.eventDate,
      notes: this.state.notes
    }
    this.props.sendPropertyEvent(newEvent, this.props.propertyId, this.props.addPropertyEventLocal, this.props.handleCloseEvent, this.clearData)
  };

  render () {
    const { classes } = this.props
    const { errors } = this.state
    return (
      <div>
        <Dialog open={this.props.opened} onClose={this.props.handleCloseEvent} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add property event</DialogTitle>
          <form className={classes.form} onSubmit={this.onSubmit} noValidate>
            <DialogContent>
              <DialogContentText>
                Add an event on this property. Only events marked as Invoice or Payment will affect the current owed amount on the property.
              </DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <RadioGroup row aria-label="eventType" id="eventType" name="eventType" value={this.state.eventType} onChange={this.onChange}>
                      <FormControlLabel value={'0'} control={<Radio />} label="Payment" />
                      <FormControlLabel value={'1'} control={<Radio />} label="Invoice" />
                      <FormControlLabel value={'2'} control={<Radio />} label="Note" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/DD/YYYY"
                        fullWidth
                        id="eventDate"
                        name="eventDate"
                        label="Event Date"
                        value={this.state.eventDate || moment()}
                        onChange={date => this.onChangeDate(date, 'eventDate')}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="amount"
                        name="amount"
                        variant="outlined"
                        fullWidth
                        id="amount"
                        label="Amount"
                        placeholder="$99.00"
                        autoFocus
                        onChange={this.onChange}
                        value={this.state.amount}
                        error={!!errors.amount}
                        helperText={errors.amount}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        multiline
                        rows={5}
                        rowsMax={8}
                        name="notes"
                        label="Notes"
                        id="notes"
                        onChange={this.onChange}
                        value={this.state.notes}
                        error={!!errors.notes}
                        helperText={errors.notes}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary" style={{ color: 'red' }}>
                    {errors.captcha && errors.captcha}
                    </Typography>
                  </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.handleCloseEvent} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Add
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    )
  }
}
PropertyEventModal.propTypes = {
  sendPropertyEvent: PropTypes.func.isRequired,
  addPropertyEventLocal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  propertyId: PropTypes.string.isRequired,
  handleCloseEvent: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  properties: state.properties,
  errors: state.errors
})
export default connect(
  mapStateToProps,
  { sendPropertyEvent }
)(withStyles(styles)(PropertyEventModal))
