import React from 'react'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import PropTypes from 'prop-types'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import {
  TextField,
  Typography,
  Grid
} from '@material-ui/core'

function PropertyInfo (props) {
  const {
    errors,
    onChange,
    onChangeDate,
    propertyInfo
  } = props
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h5" gutterBottom>
            Property
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          required
          margin="dense"
          autoFocus
          fullWidth
          id="locationStreet"
          label="Street"
          name="location.street"
          onChange={onChange}
          value={propertyInfo.location.street || ''}
          error={!!errors.locationStreet}
          helperText={errors.locationStreet}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="locationCity"
          label="City"
          name="location.city"
          onChange={onChange}
          value={propertyInfo.location.city || ''}
          error={!!errors.locationCity}
          helperText={errors.locationCity}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          variant="outlined"
          required
          fullWidth
          margin="dense"
          id="locationState"
          label="State"
          name="location.state"
          onChange={onChange}
          value={propertyInfo.location.state || ''}
          error={!!errors.locationState}
          helperText={errors.locationState}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="locationZipCode"
          label="Zipcode"
          name="location.zipCode"
          onChange={onChange}
          value={propertyInfo.location.zipCode || ''}
          error={!!errors.locationZipCode}
          helperText={errors.locationZipCode}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <KeyboardDatePicker
            variant="inline"
            inputVariant="outlined"
            format="MM/DD/YYYY"
            margin="dense"
            fullWidth
            placeholder="01/01/2020"
            id="contractStartDate"
            name="contractStartDate"
            label="Contract Start Date"
            value={propertyInfo.contractStartDate || null}
            onChange={date => onChangeDate(date, 'contractStartDate')}
          />
        </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6} sm={3}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              variant="inline"
              inputVariant="outlined"
              format="MM/DD/YYYY"
              margin="dense"
              fullWidth
              placeholder="01/01/2020"
              id="contractEndDate"
              name="contractEndDate"
              label="Contract End Date"
              value={propertyInfo.contractEndDate || null}
              onChange={date => onChangeDate(date, 'contractEndDate')}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            id="price"
            label="Contract Cost"
            name="price"
            onChange={onChange}
            value={propertyInfo.price || ''}
            error={errors.email}
          />
      </Grid>
    </Grid>
  )
}

PropertyInfo.propTypes = {
  errors: PropTypes.object.isRequired,
  propertyInfo: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired
}

export default PropertyInfo
