import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import { Helmet } from 'react-helmet'

import { signPropertyContract } from '../../actions/propertyActions'

import { withStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import MomentUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import {
  Container,
  Grid,
  Chip,
  Typography,
  TextField,
  Paper,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh'
  },
  propertyEventGrid: {
    marginTop: theme.spacing(3)
  },
  map: {
    height: '300px',
    margin: '0 auto',
    width: '100%'
  },
  propertyInfo: {
    marginBottom: theme.spacing(2)
  },
  fabEdit: {
    position: 'fixed',
    '@media (min-width: 1px)': {
      bottom: '5px',
      right: '5px'
    },
    '@media (min-width: 600px)': {
      bottom: '20px',
      right: '20px'
    },
    '@media (min-width: 976px)': {
      bottom: '50px',
      right: '50px'
    }
  },
  fabDelete: {
    position: 'fixed',
    '@media (min-width: 1px)': {
      bottom: '5px',
      left: '5px'
    },
    '@media (min-width: 600px)': {
      bottom: '20px',
      left: '20px'
    },
    '@media (min-width: 976px)': {
      bottom: '50px',
      left: '50px'
    }
  },
  alert: {
    margin: theme.spacing(1)
  }
})

class Sign extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sign: null,
      firstName: '',
      lastName: '',
      email: '',
      date: moment().format(),
      errors: {}
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    const signId = this.props.location.pathname.replace('/sign/', '')

    axios
      .get('/api/properties/sign/' + signId)
      .then(res => {
        this.setState({ sign: res.data })
      })
      .catch(err => console.log('Error, get signature failed with: ' + err))
  }

  componentDidUpdate (prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({
        errors: this.props.errors
      })
    }
  }

  onChange (e) {
    this.setState({ [e.target.id]: e.target.value })
  }

  onSubmit (e) {
    e.preventDefault()
    const signature = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      date: this.state.date,
      signId: this.props.location.pathname.replace('/sign/', ''),
      propertyId: this.state.sign._id
    }

    this.props.signPropertyContract(signature, this.props.history)
  };

  render () {
    const { errors, sign } = this.state
    const { classes } = this.props
    console.log(this.state.date)
    return (
      <Container maxWidth="lg">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sign | CaretakerDB</title>
          <link rel="canonical" href="https://caretakerdb.com/sign" />
          <meta name="description" content="Sign your caretaking contract here to agree to all contract terms and conditions." />
        </Helmet>
        <Paper className={classes.paper}>
          <Typography variant="h3" color="secondary">Caretaking Contract</Typography>
          <Typography variant="h6" color="secondary">Please confirm all of the following information is correct and sign at the bottom.</Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>Electronically sign your caretaking contract from the comfort of your home.</Typography>
          {this.state.sign
            ? <div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Card className={classes.propertyInfo}>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Property Location
                      </Typography>
                      <Typography variant="h4" component="h4">
                        {sign.location.street}
                      </Typography>
                      <Typography color="textSecondary">
                        {sign.location.city}, {sign.location.state} {sign.location.zipCode}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Contract notifications
                      </Typography>

                      {sign.currentOwed <= 0
                        ? <Alert severity="info" className={classes.alert}>No payment due</Alert>
                        : <Alert severity="error" className={classes.alert}>Payment due</Alert>}
                      <Alert severity="info" className={classes.alert}>Account balance: <b>${sign.currentOwed}</b></Alert>
                      <Alert severity="info" className={classes.alert}>
                      {sign.contractEndDate && sign.contractStartDate ? `${moment(sign.contractStartDate).format('MMMM Do YYYY')} to ${moment(sign.contractEndDate).format('MMMM Do YYYY')}` : 'No period data'}
                      </Alert>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} height="100%">
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Property Owner
                      </Typography>
                      <Typography variant="h5" component="h5">
                        {sign.owner.firstName} {sign.owner.lastName}
                      </Typography>
                      <Typography className={classes.pos} color="textSecondary">
                        {sign.owner.address.city}, {sign.owner.address.state}
                      </Typography>
                      <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">Location</TableCell>
                            <TableCell align="right">{sign.owner.address.street}, {sign.owner.address.city}, {sign.owner.address.state} {sign.owner.address.zipCode}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Email</TableCell>
                            <TableCell align="right">{sign.owner.email}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Cell Phone</TableCell>
                            <TableCell align="right">{sign.owner.cellPhone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Home Phone</TableCell>
                            <TableCell align="right">{sign.owner.homePhone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Office Phone</TableCell>
                            <TableCell align="right">{sign.owner.officePhone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Other Phone</TableCell>
                            <TableCell align="right">{sign.owner.otherPhone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Plowing</TableCell>
                            <TableCell align="right">{sign.owner.plowing}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Alarm code</TableCell>
                            <TableCell align="right">{sign.owner.alarmCode}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Additional</TableCell>
                            <TableCell align="right">{sign.owner.additional}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} height="100%">
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Property Services
                      </Typography>
                      <Typography variant="h5" component="h5">
                        Services
                      </Typography>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell component="th" scope="row">Service</TableCell>
                            <TableCell align="right">Contact</TableCell>
                            <TableCell align="right">Phone</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">Irrigation</TableCell>
                            <TableCell align="right">{sign.services.irrigation.contact}</TableCell>
                            <TableCell align="right">{sign.services.irrigation.phone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Plumbing</TableCell>
                            <TableCell align="right">{sign.services.plumber.contact}</TableCell>
                            <TableCell align="right">{sign.services.plumber.phone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Electrician</TableCell>
                            <TableCell align="right">{sign.services.electrician.contact}</TableCell>
                            <TableCell align="right">{sign.services.electrician.phone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Carpentry</TableCell>
                            <TableCell align="right">{sign.services.carpenter.contact}</TableCell>
                            <TableCell align="right">{sign.services.carpenter.phone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Appliance</TableCell>
                            <TableCell align="right">{sign.services.appliance.contact}</TableCell>
                            <TableCell align="right">{sign.services.appliance.phone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Furnace</TableCell>
                            <TableCell align="right">{sign.services.furnace.contact}</TableCell>
                            <TableCell align="right">{sign.services.furnace.phone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Cleaner</TableCell>
                            <TableCell align="right">{sign.services.cleaner.contact}</TableCell>
                            <TableCell align="right">{sign.services.cleaner.phone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Boats and Docks</TableCell>
                            <TableCell align="right">{sign.services.boatsAndDocks.contact}</TableCell>
                            <TableCell align="right">{sign.services.boatsAndDocks.phone}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} height="100%">
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Representative to Notify
                      </Typography>
                      <Typography variant="h5" component="h5">
                        {sign.owner.repToNotify}
                      </Typography>
                      <Typography className={classes.pos} color="textSecondary">
                        {sign.owner.repAddress.city}, {sign.owner.repAddress.state}
                      </Typography>
                      <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">Location</TableCell>
                            <TableCell align="right">{sign.owner.repAddress.street}, {sign.owner.repAddress.city}, {sign.owner.repAddress.state} {sign.owner.repAddress.zipCode}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Rep Phone</TableCell>
                            <TableCell align="right">{sign.owner.repPhone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Rep Second Phone</TableCell>
                            <TableCell align="right">{sign.owner.repSecondPhone}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} height="100%">
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Property Special
                      </Typography>
                      <Typography variant="h5" component="h5">
                        Special
                      </Typography>
                      <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">Outside Shower</TableCell>
                            <TableCell align="right">{sign.special.outsideShower ? 'Yes' : 'No'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Outside Faucet</TableCell>
                            <TableCell align="right">{sign.special.outsideFaucet ? 'Yes' : 'No'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Outside Spa</TableCell>
                            <TableCell align="right">{sign.special.outsideSpa ? 'Yes' : 'No'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Other</TableCell>
                            <TableCell align="right">{sign.special.other}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} height="100%">
                </Grid>
              </Grid>
              <Typography variant="body1" color="secondary" gutterBottom>By submitting this form you agree that you are representing <strong>{this.state.sign.owner.firstName} {this.state.sign.owner.lastName}</strong>&nbsp;
              and signing the contract for the property at <strong>{this.state.sign.location.street}</strong>. This electronic signature is equivalent to a real signature and is an agreement to pay for any and all
              services rendered by the caretaker.</Typography>
              <form className={classes.form} onSubmit={this.onSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="fname"
                        name="firstName"
                        variant="outlined"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        autoFocus
                        onChange={this.onChange}
                        value={this.state.firstName}
                        error={!!errors.firstName}
                        helperText={errors.firstName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="lname"
                        onChange={this.onChange}
                        value={this.state.lastName}
                        error={!!errors.lastName}
                        helperText={errors.lastName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        onChange={this.onChange}
                        value={this.state.email}
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        inputVariant="outlined"
                        format="MM/DD/YYYY"
                        fullWidth
                        disabled
                        id="contractStartDate"
                        name="contractStartDate"
                        label="Signing Date"
                        value={moment().format('MM/DD/YYYY')}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid container spacing={2} justify="flex-start">
                  <Grid item xs={12}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign
                    </Button>
                  </Grid>
                </Grid>
              </form>
          </div>
            : <Chip
              label="No signature request found"
              color="secondary"
            />}
        </Paper>
      </Container>
    )
  }
}

Sign.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  signPropertyContract: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})
export default connect(
  mapStateToProps,
  { signPropertyContract }
)(withRouter(withStyles(styles)(Sign)))
