import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { withStyles } from '@material-ui/core/styles'

import PropertyEventOptions from './PropertyEventOptions'
import { eventTypes } from './EventTypes.js'
import { deleteExistingPropertyEvent } from '../../../actions/propertyActions'

const styles = theme => ({
  alert: {
    marginBottom: theme.spacing(3)
  }
})

class PropertyEventView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hovered: false,
      optionsOpen: false,
      alertOpen: false,
      errors: {}
    }
    this.openOptions = this.openOptions.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
  };

  openOptions () {
    this.setState({ optionsOpen: true })
  }

  handleDeleteClose () {
    this.props.deleteExistingPropertyEvent(this.props.event.eventId, this.props.event.propertyId)
    this.props.removeEvent(this.props.event.eventId)
    this.setState({ optionsOpen: false })
  }

  render () {
    const { event, classes } = this.props
    return (
      <Grid item xs={12} md={4}>
        <Card margin={2} raised={true}>
          <CardHeader
            avatar={
                <Typography color="secondary" align="center">
                  <HomeWorkIcon fontSize="large" />
                </Typography>
            }
            action={
              <IconButton aria-label="settings" onClick={this.openOptions}>
                <MoreVertIcon />
              </IconButton>
            }
            title={eventTypes[event.eventType]}
            subheader={moment(event.eventDate).format('MM/DD/YYYY')}
          />
          <Divider />
          <CardContent>
            <Typography variant="body1" color="primary" component="p" gutterBottom>
              <b>Notes:</b> {event.notes}
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="body1" color="textSecondary" component="p" gutterBottom>
              <b>Amount:</b> ${event.amount ? event.amount : 0}
            </Typography>
          </CardContent>
          <PropertyEventOptions menuOpen={this.state.optionsOpen} handleClose={() => this.setState({ optionsOpen: false })} handleCloseDelete={this.handleDeleteClose} classes={classes} event={event} />
        </Card>
      </Grid>
    )
  }
}
PropertyEventView.propTypes = {
  event: PropTypes.object,
  loading: PropTypes.bool,
  deleteExistingPropertyEvent: PropTypes.func,
  removeEvent: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  properties: state.properties
})
export default connect(
  mapStateToProps,
  { deleteExistingPropertyEvent }
)(withStyles(styles)(PropertyEventView))
