import axios from 'axios'
import {
  GET_ERRORS
} from './types'
// Register User

export const sendContact = (contactData, history) => dispatch => {
  axios
    .post('/api/mail/contact', contactData)
    .then(res => history.push('/')) // re-direct to login on successful register
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
    )
}
