import React from 'react'
import ContractForm from './ContractForm/containers/ContractForm'

function NewProperty (props) {
  return (
    <ContractForm {...props} />
  )
}

export default NewProperty
