import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import { sendContact } from '../../actions/contactActions'

import ReCAPTCHA from 'react-google-recaptcha'

import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

const styles = theme => ({

})

const CAPTCHA_KEY_PUBLIC = '6LeyzN4UAAAAAMrRM91WnAv00TM5jDH3nyJEd-nD'

class Contact extends Component {
  constructor (props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      message: '',
      captcha: '',
      errors: {}
    }
    this._reCaptchaRef = React.createRef()
    this.onChange = this.onChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({
        errors: this.props.errors
      })
    }
  }

  onChange (e) {
    this.setState({ [e.target.id]: e.target.value })
  };

  handleChange (val) {
    this.setState({ captcha: val })
  };

  onSubmit (e) {
    e.preventDefault()
    const newContact = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      subject: this.state.subject,
      message: this.state.message,
      captcha: this.state.captcha
    }
    this.props.sendContact(newContact, this.props.history)
  };

  render () {
    const { errors } = this.state
    const { classes } = this.props
    return (
      <Container maxWidth="md">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact | CaretakerDB</title>
          <link rel="canonical" href="https://caretakerdb.com/contact" />
          <meta name="description" content="CaretakerDB contact page, let us know of any suggestions or questions." />
        </Helmet>
        <Typography variant="h3" color="secondary" component="h3" gutterBottom>Contact us</Typography>
        <ul>
          <li>Email inquires: <a href="mailto:gavin@caretakerdb.com">gavin@caretakerdb.com</a></li>
          <li>Phone: <a href="tel:15089393072">(508) 939 - 3072</a></li>
        </ul>
        <Typography variant="h6" color="secondary" component="h6" gutterBottom>Contact Form</Typography>
        <br />
        <form className={classes.form} onSubmit={this.onSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={this.onChange}
                  value={this.state.firstName}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  onChange={this.onChange}
                  value={this.state.lastName}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={this.onChange}
                  value={this.state.email}
                  error={!!errors.email}
                  helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="subject"
                  label="Subject"
                  name="subject"
                  autoComplete="subject"
                  onChange={this.onChange}
                  value={this.state.subject}
                  error={!!errors.subject}
                  helperText={errors.subject}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  rows={5}
                  rowsMax={8}
                  name="message"
                  label="Message"
                  id="message"
                  onChange={this.onChange}
                  value={this.state.message}
                  error={!!errors.message}
                  helperText={errors.message}
              />
            </Grid>
            <Grid item xs={12}>
              <ReCAPTCHA
                ref={this._reCaptchaRef}
                id="captcha"
                sitekey={CAPTCHA_KEY_PUBLIC}
                onChange={this.handleChange}
              />
              <Typography variant="body2" color="textSecondary" style={{ color: 'red' }}>
              {errors.captcha && errors.captcha}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} justify="flex-start">
            <Grid item xs={12}>
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
              >
                  Send
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    )
  }
}

Contact.propTypes = {
  sendContact: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})
export default connect(
  mapStateToProps,
  { sendContact }
)(withRouter(withStyles(styles)(Contact)))
