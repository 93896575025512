import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'

import LongLogo from './img/long_logo.png'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AddIcon from '@material-ui/icons/Add'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import HomeIcon from '@material-ui/icons/Home'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import LockIcon from '@material-ui/icons/Lock'
import MoreIcon from '@material-ui/icons/MoreVert'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import { logoutUser } from '../../actions/authActions'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    textAlign: 'center'
  },
  navBarLogo: {
    height: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48
    },
    [theme.breakpoints.up('sm')]: {
      height: 64
    }
  },
  navBarImage: {
    margin: 'auto',
    display: 'block',
    height: '100%'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  list: {
    width: 250
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  fullList: {
    width: 'auto'
  },
  grow: {
    flexGrow: 1
  }
}))

export function Navbar (props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const sideList = side => (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper
      }}
      variant="permanent"
      anchor="left"
    >
      <div className={classes.navBarLogo}>
        <img src={LongLogo} alt="CaretakerDB Logo" className={classes.navBarImage} />
      </div>
      <Divider />
      <List>
        <ListItem button
          key="Home"
          component={Link}
          to="/">
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      </List>
      <Divider />
      {props.auth.isAuthenticated &&
        <><List>
          <ListItem button
            key="New property"
            component={Link}
            to="/new-property">
              <ListItemIcon><AddIcon /></ListItemIcon>
              <ListItemText primary="New property" />
          </ListItem>
          <ListItem
            button
            key="Upgrade"
            component={Link}
            to="/membership">
            <ListItemIcon><VerifiedUserIcon /></ListItemIcon>
            <ListItemText primary="Upgrade" />
          </ListItem>
          </List><Divider /></>}
        <List>
          <ListItem button
            key="About"
            component={Link}
            to="/about">
              <ListItemIcon><EmojiPeopleIcon /></ListItemIcon>
              <ListItemText primary="About" />
          </ListItem>
          <ListItem button
            key="Contact"
            component={Link}
            to="/contact">
              <ListItemIcon><QuestionAnswerIcon /></ListItemIcon>
              <ListItemText primary="Contact" />
          </ListItem>
          </List>
      <Divider />
      {props.auth.isAuthenticated
        ? <List>
          <ListItem
            button
            key="Profile"
            component={Link}
            to="/profile">
            <ListItemIcon><AccountCircleIcon /></ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem
          button
          key="Log out"
          onClick={onLogoutClick}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItem>
        </List>
        : <List>
              <ListItem
              button
              key="Log in"
              component={Link}
              to="/login">
                <ListItemIcon><LockIcon /></ListItemIcon>
                <ListItemText primary="Log in" />
              </ListItem>
              <ListItem
              button
              key="Register"
              component={Link}
              to="/register">
                <ListItemIcon><PersonAddIcon /></ListItemIcon>
                <ListItemText primary="Register" />
              </ListItem>
              </List>}
    </Drawer>
  )

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const onLogoutClick = e => {
    e.preventDefault()
    handleMenuClose()
    props.logoutUser()
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {props.auth.isAuthenticated
        ? <Container><MenuItem onClick={handleMenuClose} component={Link} to="/profile">Profile</MenuItem>
      <MenuItem onClick={onLogoutClick}>Log out</MenuItem></Container>
        : <div><MenuItem onClick={handleMenuClose} component={Link} to="/login">Log in</MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/register">Register</MenuItem></div>
      }
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {props.auth.isAuthenticated
        ? <Container>
        <MenuItem onClick={handleMenuClose} component={Link} to="/profile">Profile</MenuItem>
        <MenuItem onClick={onLogoutClick}>Log out</MenuItem>
      </Container>
        : <Container>
        <MenuItem component={Link} to="/login">
          <p>Log in</p>
        </MenuItem>
        <MenuItem component={Link} to="/register">
          <p>Register</p>
        </MenuItem>
      </Container>}
    </Menu>
  )
  return (
      <div className={'navBarMain'}>
        {sideList('left')}
        <AppBar className={classes.appBar}>
          <Toolbar>
            <div className={classes.grow} />
              <div className={classes.sectionDesktop}>

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </div>
  )
}
Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  properties: state.properties
})
export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(Navbar))
