import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'
import { withStyles } from '@material-ui/core/styles'
import { downgradeUserMembership } from '../../actions/propertyActions'

import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe('pk_live_amHhy6F9Pw85nsHoLwdiZAD4')

const styles = theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    }
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    flexWrap: 'wrap'
  },
  toolbarTitle: {
    flexGrow: 1
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6)
  },
  cardHeader: {
    backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2)
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  },
  noMargin: {
    margin: 0
  }
})

const tiers = [
  {
    title: 'Free',
    price: '0',
    payPeriod: 'mo',
    description: [
      '3 contracts',
      'Email support'],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
    id: 0
  },
  {
    title: 'Business',
    subheader: 'Most popular - Annual',
    price: '149',
    payPeriod: 'yr',
    description: [
      'Unlimited contracts',
      'Priority email support',
      'Free contract upload support',
      '30 day money-back free trial'
    ],
    buttonText: 'Get started',
    buttonVariant: 'outlined',
    priceId: 'price_1H4FuWHtZnc7DKDDqHyEoBr0',
    id: 1
  },
  {
    title: 'Personal',
    subheader: 'Best for smaller operations',
    price: '99',
    payPeriod: 'yr',
    description: [
      '15 contracts',
      'Email support',
      '30 day money-back free trial'
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
    priceId: 'price_1H4Fy6HtZnc7DKDDm4N2upE5',
    id: 2
  }
]

class DowngradeAlert extends Component {
  constructor (props) {
    super(props)

    this.handleDowngrade = this.handleDowngrade.bind(this)
  }

  handleDowngrade () {
    this.props.downgradeUserMembership()
  }

  render () {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Downgrade your service?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Downgrading is immediate and will cancel your subscription. If you are still in the
            trial period you will not be charged at the end of the trial, but if you have paid
            you will continue to have the upgraded version until the end of the subscription
            period. At the end of that subscription period you will not be renewed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="secondary" autoFocus>
            Cancel
          </Button>
          <Button onClick={this.handleDowngrade} color="primary">
            Downgrade
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DowngradeAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  downgradeUserMembership: PropTypes.func.isRequired
}

class UpgradeButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      downgrade: false
    }
    this.handleClickUpgrade = this.handleClickUpgrade.bind(this)
    this.handleClickDowngrade = this.handleClickDowngrade.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  async handleClickUpgrade (event) {
    event.preventDefault()
    const { stripe, priceId } = this.props

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    stripe.redirectToCheckout({
      lineItems: [
        // Replace with the ID of your price
        { price: priceId, quantity: 1 }
      ],
      mode: 'subscription',
      clientReferenceId: this.props.auth.user.id,
      customerEmail: this.props.auth.user.email,
      successUrl: 'https://caretakerdb.com/success',
      cancelUrl: 'https://caretakerdb.com/cancelled'
    })
      .then(function (result) {
        if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer.
          console.log(result)
        }
      })
  };

  async handleClickDowngrade (event) {
    event.preventDefault()
    this.setState({ downgrade: true })
  };

  handleClose () {
    this.setState({ downgrade: false })
  }

  render () {
    const { tier, auth } = this.props
    return (
      <>
        <Button
          fullWidth
          variant={tier.buttonVariant}
          color="primary"
          onClick={tier.id === 0 || (tier.id === 2 && auth.user.membershipId === 1) ? this.handleClickDowngrade : this.handleClickUpgrade}
          disabled={tier.id === auth.user.membershipId}>
          {tier.id === auth.user.membershipId ? 'Current plan' : tier.id === 0 || (tier.id === 2 && auth.user.membershipId === 1) ? 'Downgrade' : 'Upgrade now'}
        </Button>
        <DowngradeAlert open={this.state.downgrade} downgradeUserMembership={this.props.downgradeUserMembership} history={this.props.history} handleClose={this.handleClose} />
      </>
    )
  }
}

UpgradeButton.propTypes = {
  tier: PropTypes.number.isRequired,
  auth: PropTypes.object.isRequired,
  downgradeUserMembership: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  priceId: PropTypes.string.isRequired
}

class InjectedUpgradeButton extends Component {
  render () {
    return (
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <UpgradeButton
            stripe={stripe}
            auth={this.props.auth}
            tier={this.props.tier}
            elements={elements}
            history={this.props.history}
            priceId={this.props.tier.priceId}
            downgradeUserMembership={this.props.downgradeUserMembership} />
        )}
      </ElementsConsumer>
    )
  }
}

InjectedUpgradeButton.propTypes = {
  auth: PropTypes.object.isRequired,
  tier: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  downgradeUserMembership: PropTypes.func.isRequired
}

class Tiers extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { classes } = this.props
    return (
      <Grid container spacing={5} alignItems="flex-end" className={classes.noMargin}>
        {tiers.map((tier) => (
          // Enterprise card is full width at sm breakpoint
          <Grid item key={tier.id} xs={12} sm={tier.title === 'Business' ? 12 : 6} md={4}>
            <Card>
              <CardHeader
                title={tier.title}
                subheader={tier.subheader}
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                action={tier.id === 1 ? <StarIcon /> : null}
                className={classes.cardHeader}
              />
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography component="h2" variant="h3" color="textPrimary">
                    ${tier.price}
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    /{tier.payPeriod}
                  </Typography>
                </div>
                <ul>
                  {tier.description.map((line) => (
                    <Typography component="li" variant="subtitle1" align="center" key={line}>
                      {line}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
              <CardActions>
                  {this.props.auth.isAuthenticated
                    ? <Elements stripe={stripePromise}>
                    <InjectedUpgradeButton auth={this.props.auth} tier={tier} history={this.props.history} downgradeUserMembership={this.props.downgradeUserMembership} />
                  </Elements>
                    : <Button
                    fullWidth
                    variant={tier.buttonVariant}
                    color="primary"
                    to="/register"
                    component={Link}>
                    {tier.buttonText}
                  </Button>
                  }
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    )
  }
}
Tiers.propTypes = {
  auth: PropTypes.object.isRequired,
  downgradeUserMembership: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth
})
export default connect(
  mapStateToProps,
  { downgradeUserMembership }
)(withStyles(styles)(Tiers))
