import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

class Print extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hovered: false,
      opened: false,
      events: null
    }
    this._isMounted = false
    this.getNiceDate = this.getNiceDate.bind(this)
    this.getPropertyTotalOwed = this.getPropertyTotalOwed.bind(this)
  };

  componentWillUnmount () {
    // to prevent attempting setState after component is unmounted
    this._isMounted = false
  }

  getNiceDate () {
    const property = this.props.property
    const tempSign = property && moment(property.terms.date)
    const signedDate = tempSign.format('MM/DD/YYYY')
    return signedDate
  }

  getPropertyTotalOwed () {
    const property = this.props.property
    let runningTotal = -(property.price)

    if (this.state.events) {
      for (const e in this.state.events) {
        const event = this.state.events[e]
        if (event.eventType === 1) {
          runningTotal -= event.amount
        } else if (event.eventType === 0) {
          runningTotal += event.amount
        }
      }
    }

    return runningTotal
  }

  render () {
    const property = this.props.property
    const signedDate = this.getNiceDate()
    const totalOwed = this.getPropertyTotalOwed()
    const eventTypes = {
      0: 'Payment',
      1: 'Invoice',
      2: 'Note'
    }
    return (
      <Container component="main" maxWidth="lg">
        {property &&
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography color="secondary" variant="h2" component="h2">{this.props.companyName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="secondary" gutterBottom>
                <b>Property Location</b>: {property.location.street} {property.location.city}, {property.location.state} {property.location.zipCode}
              </Typography>
              <Typography color="secondary" gutterBottom>
                <b>Current Owed</b>: {totalOwed < 0 ? <span style={{ color: 'red' }}>${totalOwed}</span> : <span style={{ color: 'green' }}>${totalOwed}</span>}
              </Typography>
              <Typography color="secondary">
                <b>Season</b>: {property.season}
              </Typography>
            </Grid>
            <Grid item xs={12} height="100%">
              <Typography color="secondary" gutterBottom>
                <b>Property Owner</b>: {property.owner.firstName} {property.owner.lastName}
              </Typography>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">Location</TableCell>
                    <TableCell align="right">{property.owner.address.street}, {property.owner.address.city}, {property.owner.address.state} {property.owner.address.zipCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Email</TableCell>
                    <TableCell align="right">{property.owner.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Cell Phone</TableCell>
                    <TableCell align="right">{property.owner.cellPhone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Home Phone</TableCell>
                    <TableCell align="right">{property.owner.homePhone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Office Phone</TableCell>
                    <TableCell align="right">{property.owner.officePhone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Other Phone</TableCell>
                    <TableCell align="right">{property.owner.otherPhone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Plowing</TableCell>
                    <TableCell align="right">{property.owner.plowing}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Alarm code</TableCell>
                    <TableCell align="right">{property.owner.alarmCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Additional</TableCell>
                    <TableCell align="right">{property.owner.additional}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
              <Typography color="secondary" gutterBottom>
                <b>Representative to Notify</b>: {property.owner.repToNotify}
              </Typography>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">Location</TableCell>
                    <TableCell align="right">{property.owner.repAddress.city && `${property.owner.repAddress.street}, ${property.owner.repAddress.city}, ${property.owner.repAddress.state} ${property.owner.repAddress.zipCode}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Rep Phone</TableCell>
                    <TableCell align="right">{property.owner.repPhone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Rep Second Phone</TableCell>
                    <TableCell align="right">{property.owner.repSecondPhone}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} height="100%">
              <Typography color="secondary" gutterBottom>
                Property Services
              </Typography>
              <Typography variant="h5" component="h5">
                Services
              </Typography>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row">Service</TableCell>
                    <TableCell align="right">Contact</TableCell>
                    <TableCell align="right">Phone</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">Irrigation</TableCell>
                    <TableCell align="right">{property.services.irrigation.contact}</TableCell>
                    <TableCell align="right">{property.services.irrigation.phone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Plumbing</TableCell>
                    <TableCell align="right">{property.services.plumber.contact}</TableCell>
                    <TableCell align="right">{property.services.plumber.phone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Electrician</TableCell>
                    <TableCell align="right">{property.services.electrician.contact}</TableCell>
                    <TableCell align="right">{property.services.electrician.phone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Carpentry</TableCell>
                    <TableCell align="right">{property.services.carpenter.contact}</TableCell>
                    <TableCell align="right">{property.services.carpenter.phone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Appliance</TableCell>
                    <TableCell align="right">{property.services.appliance.contact}</TableCell>
                    <TableCell align="right">{property.services.appliance.phone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Furnace</TableCell>
                    <TableCell align="right">{property.services.furnace.contact}</TableCell>
                    <TableCell align="right">{property.services.furnace.phone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Cleaner</TableCell>
                    <TableCell align="right">{property.services.cleaner.contact}</TableCell>
                    <TableCell align="right">{property.services.cleaner.phone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Boats and Docks</TableCell>
                    <TableCell align="right">{property.services.boatsAndDocks.contact}</TableCell>
                    <TableCell align="right">{property.services.boatsAndDocks.phone}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={6} md={6} height="100%">
              <Typography color="secondary" gutterBottom>
                Property Special
              </Typography>
              <Typography variant="h5" component="h5">
                Special
              </Typography>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">Outside Shower</TableCell>
                    <TableCell align="right">{property.special.outsideShower ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Outside Faucet</TableCell>
                    <TableCell align="right">{property.special.outsideFaucet ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Outside Spa</TableCell>
                    <TableCell align="right">{property.special.outsideSpa ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Other</TableCell>
                    <TableCell align="right">{property.special.other}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={6} md={6} height="100%">
              <Typography color="secondary" gutterBottom>
                Property Contract
              </Typography>
              <Typography variant="h5" component="h5">
                Contract
              </Typography>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">Dated</TableCell>
                    <TableCell align="right">{signedDate || 'Not signed yet'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Signed by</TableCell>
                    <TableCell align="right">{property.terms.signed ? property.terms.signed : 'Not signed yet'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} height="100%">
              <Typography color="secondary" gutterBottom>
                Property Events
              </Typography>
              <Typography variant="h5" component="h5">
                Event History
              </Typography>
              <Table aria-label="simple table">
                <TableBody>
                  {this.state.events && this.state.events.length > 0 && this.state.events.map((event, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">{moment(event.eventDate).format('MM/DD/YYYY')}</TableCell>
                        <TableCell align="left">{eventTypes[event.eventType]}</TableCell>
                        <TableCell align="right">{event.notes}</TableCell>
                        <TableCell align="right">${event.amount}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        }
      </Container>
    )
  }
}
Print.propTypes = {
  auth: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
  companyName: PropTypes.string.isRequired
}
export default Print
