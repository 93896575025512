import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { latLngBounds } from 'leaflet'
import { getProperties } from '../../actions/propertyActions'
import Property from './Property'
import { Container, Grid, Typography, Fab, Snackbar } from '@material-ui/core'
import { Link } from 'react-router-dom'
import CardActionArea from '@material-ui/core/CardActionArea'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import Skeleton from '@material-ui/lab/Skeleton'
import AddIcon from '@material-ui/icons/Add'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import { withStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import Location from './img/find_house.svg'

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  link: {
    '&:hover': {
      textDecoration: 'none'
    },
    textDecoration: 'none'
  },
  paper: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  dashPaper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  map: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: '800px',
    width: '100%',
    margin: '0 auto'
  },
  viewSelectForm: {
    width: '100%',
    minWidth: 80,
    marginBottom: theme.spacing(2)
  }
})

class MapView extends Component {
  constructor (props) {
    super(props)
    this.getBounds = this.getBounds.bind(this)
  }

  getBounds () {
    const bounds = latLngBounds()
    this.props.properties.forEach(function (property) {
      if (property.location.lat !== '-1' && property.location.lon !== '-1') {
        bounds.extend([property.location.lat, property.location.lon])
      }
    })

    return bounds
  }

  render () {
    const { properties, classes } = this.props
    return (
      <Map
        ref={() => 'map'}
        center={[properties[0].location.lat, properties[0].location.lon]}
        zoom={this.props.zoom}
        bounds={this.getBounds()}
        boundsOptions={{ padding: [50, 50] }}
        className={ classes.map }>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
        {properties.map(function (property, i) {
          if (property.location.lat !== '-1' && property.location.lon !== '-1') {
            return (<Marker position={[property.location.lat, property.location.lon]} key={i}>
              <Popup><Link to={`/property/${property._id}`}>{property.location.street}</Link></Popup>
            </Marker>)
          } else {
            return false
          }
        })}
      </Map>
    )
  }
}

MapView.propTypes = {
  properties: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired
}

class Dashboard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hovered: false,
      zoom: 12,
      bounds: null,
      orderChoice: 0,
      viewChoice: 0,
      properties: this.props.properties.properties
    }
    this.handleViewChange = this.handleViewChange.bind(this)
    this.handleOrderChange = this.handleOrderChange.bind(this)
    this.orderProperties = this.orderProperties.bind(this)
  };

  componentDidMount () {
    this.props.getProperties(this.props.auth.user.id)
  };

  componentDidUpdate (prevProps) {
    if (prevProps.properties !== this.props.properties) {
      this.setState({ properties: this.props.properties.properties })
    }
  }

  handleViewChange (e) {
    this.setState({ viewChoice: e.target.value })
  };

  handleOrderChange (e) {
    this.setState({ orderChoice: e.target.value })
  };

  orderProperties () {
    const properties = JSON.parse(JSON.stringify(this.props.properties.properties))
    if (properties && properties.length > 0) {
      switch (this.state.orderChoice) {
        case 0:
          properties.sort(function (property1, property2) {
            return property1.owner.lastName > property2.owner.lastName ? 1 : -1
          })
          break
        case 1:
          properties.sort(function (property1, property2) {
            return property1.owner.firstName > property2.owner.firstName ? 1 : -1
          })
          break
        case 2:
          properties.sort(function (property1, property2) {
            return property1.location.street > property2.location.street ? 1 : -1
          })
          break
        case 3:
          properties.sort(function (property1, property2) {
            return property1.location.city > property2.location.city ? 1 : -1
          })
          break
        default:
          properties.sort(function (property1, property2) {
            return property1.location.street > property2.location.street ? 1 : -1
          })
          break
      }
      return properties
    }
    return null
  };

  render () {
    const { user } = this.props.auth
    const properties = this.orderProperties()
    const propertiesLoaded = properties && properties.length > 0
    const { classes } = this.props
    const addCardStyles = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%'
    }
    const maxProperties = user.membershipId === 0 ? '3' : user.membershipId === 2 ? '15' : 'unlimited'
    const propertiesSnackbar = properties ? `${properties.length}/${maxProperties} properties` : `0/${maxProperties} properties`
    return (
      <Container component="main" maxWidth="lg" spacing={0}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dashboard | CaretakerDB</title>
          <link rel="canonical" href="https://caretakerdb.com/dashboard" />
          <meta name="description" content="CaretakerDB dashboard page, manage, add, and delete all your caretaking contracts." />
        </Helmet>
        <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Chip
            icon={<AttachMoneyIcon />}
            label={user.membership}
            color='secondary'
          />
        </Snackbar>
        <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Chip
            label={propertiesSnackbar}
            color='secondary'
          />
        </Snackbar>
        <Paper className={classes.dashPaper} elevation={0}>
          <Grid
            container
            spacing={3}>
            <Grid item xs={12} sm={10} height="100%">
              {user.firstName
                ? <Typography variant="h3">Welcome, {user.firstName}.</Typography>
                : <Typography variant="h3">Welcome to your Dashboard!</Typography>}
                  <Typography variant="body1" color="textSecondary" gutterBottom>This is the dashboard, you can find everything related to the management of your caretaking properties here.</Typography>
            </Grid>
            <Grid item xs={12} sm={2} height="100%">
              <FormControl variant="outlined" className={classes.viewSelectForm}>
                <InputLabel id="">View Style</InputLabel>
                <Select
                  labelId=""
                  id=""
                  value={this.state.viewChoice}
                  onChange={this.handleViewChange}
                  label="View Style"
                  fullWidth
                >
                  <MenuItem value={0}>Tile</MenuItem>
                  <MenuItem value={1}>Map</MenuItem>
                  <MenuItem value={2} disabled>List <Typography variant="body2" color="textSecondary">(coming soon)</Typography></MenuItem>
                </Select>
              </FormControl>
              {this.state.viewChoice === 0 &&
                <FormControl variant="outlined" className={classes.viewSelectForm}>
                  <InputLabel id="">Order By</InputLabel>
                  <Select
                    labelId=""
                    id=""
                    value={this.state.orderChoice}
                    onChange={this.handleOrderChange}
                    label="Order By"
                    fullWidth
                  >
                    <MenuItem value={0}>Owner last name</MenuItem>
                    <MenuItem value={1}>Owner first name</MenuItem>
                    <MenuItem value={2}>Property street</MenuItem>
                    <MenuItem value={3}>Property city</MenuItem>
                  </Select>
                </FormControl>
              }
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}>
              <Grid item xs={12} sm={12} height="100%">
              {propertiesLoaded && this.state.viewChoice === 1
                ? <MapView properties={properties} classes={classes} zoom={this.state.zoom} />
                : <div></div>}
              </Grid>
              {this.state.viewChoice === 0 && <>
              {propertiesLoaded
                ? properties.map(function (property, i) {
                  return (<Grid item xs={12} key={i}>
                    <Property property={property} />
                  </Grid>)
                })
                : <Grid item xs={12}>
                  <Card style={{ maxWidth: 345 }} margin={2}>
                    <CardHeader avatar={<Skeleton variant="circle" width={40} height={40} action={null} />}
                      title="Example property"
                      subheader="123 Apple St" />
                    <CardContent>
                      <CardMedia>
                        <img src={Location} alt="New house" width="80%" style={{ paddingLeft: '10%' }} />
                      </CardMedia>
                      <Skeleton height={6} />
                      <Skeleton height={6} width="80%" />
                      <Typography variant="body2" color="textSecondary" component="p">
                        Doesn&apos;t look like you have any properties yet. You should add one!
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              }
              <Grid item xs={12} height="100%">
                <Card style={{ height: '100%' }} raised={true}>
                  <CardActionArea to="/new-property" component={Link} style={addCardStyles}>
                    <CardContent>
                      <Typography color="textSecondary" align="center">
                        <HomeWorkIcon fontSize="large" />
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p" align="center">
                        Add a new property
                      </Typography>
                      <Typography color="textSecondary" align="center">
                        <AddIcon fontSize="large" />
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              </>
              }
              </Grid>
              <Fab aria-label="Add" style={{ position: 'fixed', right: 50, bottom: 50 }} color="primary" to="/new-property" component={Link}>
                <AddIcon />
              </Fab>
          </Paper>
      </Container>
    )
  }
}
Dashboard.propTypes = {
  getProperties: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  properties: state.properties
})
export default connect(
  mapStateToProps,
  { getProperties }
)(withStyles(styles)(Dashboard))
