import React from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'

function Special (props) {
  const {
    errors,
    onChange,
    specialInfo
  } = props
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h5" gutterBottom>
          Special
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormLabel component="legend">Select all that apply</FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox checked={specialInfo.outsideShower || false}
            name="special.outsideShower"
            id="specialOutsideShower"
            onChange={onChange} />}
            label="Outside Shower"
          />
          <FormControlLabel
            control={<Checkbox checked={specialInfo.outsideFaucet || false}
            name="special.outsideFaucet"
            id="specialOutsideFaucet"
            onChange={onChange} />}
            label="Outside Faucet"
          />
          <FormControlLabel
            control={
              <Checkbox checked={specialInfo.outsideSpa || false}
              name="special.outsideSpa"
              id="specialOutsideSpa"
              onChange={onChange} />
            }
            label="Outside Spa"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="specialOther"
          label="Other"
          name="special.other"
          onChange={onChange}
          value={specialInfo.other || ''}
          error={errors.companyName}
        />
      </Grid>
    </Grid>
  )
}

Special.propTypes = {
  onChange: PropTypes.func.isRequired,
  specialInfo: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

export default Special
