import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { resetUser } from '../../actions/authActions'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import Container from '@material-ui/core/Container'

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
})

class ResetPassword extends Component {
  constructor () {
    super()
    this.state = {
      email: '',
      errors: {}
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/dashboard') // push user to dashboard when they login
    }
    if (this.props.errors !== prevProps.errors) {
      this.setState({
        errors: this.props.errors
      })
    }
  }

  onChange (e) {
    this.setState({ [e.target.id]: e.target.value })
  };

  onSubmit (e) {
    e.preventDefault()
    const userData = {
      email: this.state.email
    }
    this.props.resetUser(userData, this.props.history)
  };

  render () {
    const { errors } = this.state
    const { classes } = this.props
    return (
      <Container maxWidth="xs">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Reset | CaretakerDB</title>
          <link rel="canonical" href="https://caretakerdb.com/reset" />
          <meta name="description" content="CaretakerDB reset password page, use your account email to be sent a unique reset link completely automated and hassle-free." />
        </Helmet>
        <Paper className={classes.paper} elevation={3}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot password
          </Typography>
          <form className={classes.form} onSubmit={this.onSubmit} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Account Email Address"
              name="email"
              autoComplete="email"
              onChange={this.onChange}
              value={this.state.email}
              error={!!errors.email}
              helperText={errors.email}
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Send reset email
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/register" variant="body2">
                  Need an account?
                </Link>
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    )
  }
}

ResetPassword.propTypes = {
  resetUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})
export default connect(
  mapStateToProps,
  { resetUser }
)(withStyles(styles)(ResetPassword))
