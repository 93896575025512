import { combineReducers } from 'redux'
import authReducer from './authReducers'
import propertyReducer from './propertyReducers'
import loadingReducer from './loadingReducer'
import errorReducer from './errorReducers'
export default combineReducers({
  auth: authReducer,
  properties: propertyReducer,
  loading: loadingReducer,
  errors: errorReducer
})
