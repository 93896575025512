import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import {
  Paper,
  Container,
  Typography,
  Grid,
  Fab
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { withStyles } from '@material-ui/core/styles'
import './css/React.css'

const styles = theme => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
})

class Success extends Component {
  constructor (props) {
    super(props)
    this.state = {
      clientSecret: '',
      errors: {}
    }
  }

  render () {
    const { classes } = this.props
    return (
      <Container component="main" maxWidth="lg" spacing={0}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Success | CaretakerDB</title>
          <link rel="canonical" href="https://caretakerdb.com/success" />
        </Helmet>
        <Paper className={classes.paper}>
          <Grid container spacing={5} alignItems="flex-end">
            <Grid item xs={12}>
              <Typography variant="h2" component="h2">Success</Typography>
              <Typography variant="body2" component="p" color="textSecondary">Your payment was successful!</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" color="secondary">Thank you for purchasing CaretakerDB Personal/Business! All payments are processed automatically and will reflect on your account <em>in just a few minutes</em>. You will be notified via email once your account upgrade has been successfully committed. Once upgraded you must log out and log back in the see changes in your account.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" color="secondary">Your purchase comes with a 30-day money back guarantee and you will not be charged until the end of the 30-day trial. If you cancel before that period you will incur <b>no</b> charges. If you do not cancel before that period, you will be charged yearly on the date 30 days after today.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" color="secondary">Please wait 24 hours before contacting us about any upgrade issues.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Fab component={Link} to="/dashboard/" color="primary">
                <ArrowBackIcon />
              </Fab>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    )
  }
}
Success.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth
})
export default connect(
  mapStateToProps
)(withStyles(styles)(Success))
