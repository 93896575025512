import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { logoutUser } from '../../actions/authActions'
import { updateExistingProperty, getProperties } from '../../actions/propertyActions'
import SaveIcon from '@material-ui/icons/Save'
import MomentUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import {
  Container,
  Button,
  Typography,
  Divider,
  Grid,
  withStyles,
  CssBaseline,
  TextField,
  Checkbox,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Fab
} from '@material-ui/core'

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    }
  },
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
})

class EditView extends Component {
  constructor (props) {
    super(props)
    const propertyId = this.props.location.pathname.replace('/edit/', '')
    const propertyOld = this.props.properties.properties.length > 0 ? this.props.properties.properties.find(prty => propertyId === prty._id) : null
    this.state = {
      expanded: false,
      property: propertyOld,
      errors: {}
    }
    this.onChange = this.onChange.bind(this)
    this.onChangeDate = this.onChangeDate.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const vals = name.split('.')
    if (vals.length === 1) {
      if (vals[0] === 'contractStartDate' || vals[0] === 'contractEndDate') {
        this.setState(prevState => ({
          ...prevState,
          property: {
            ...prevState.property,
            [vals[0]]: moment(value)
          }
        }))
      } else {
        this.setState(prevState => ({
          ...prevState,
          property: {
            ...prevState.property,
            [vals[0]]: value
          }
        }))
      }
    } else if (vals.length === 2) {
      this.setState(prevState => ({
        ...prevState,
        property: {
          ...prevState.property,
          [vals[0]]: {
            ...prevState.property[vals[0]],
            [vals[1]]: value
          }
        }
      }))
    } else if (vals.length === 3) {
      this.setState(prevState => ({
        ...prevState,
        property: {
          ...prevState.property,
          [vals[0]]: {
            ...prevState.property[vals[0]],
            [vals[1]]: {
              ...prevState.property[vals[0]][vals[1]],
              [vals[2]]: value
            }
          }
        }
      }))
    }
  }

  onChangeDate (date, dateName) {
    if (date && dateName) {
      this.setState(prevState => ({
        ...prevState,
        property: {
          ...prevState.property,
          [dateName]: date
        }
      }))
    }
  }

  onSubmit (e) {
    e.preventDefault()
    console.log(this.props)
    this.props.updateExistingProperty(this.state.property, this.props.auth.user.id, this.props.history)
  }

  componentDidMount () {
    if (!this.props.properties.properties.length) {
      this.props.getProperties(this.props.auth.user.id)
    }
  }

  componentDidUpdate (prevProps) {
    if (!this.state.property) {
      const propertyId = this.props.location.pathname.replace('/edit/', '')
      const propertyOld = this.props.properties.properties.length > 0 ? this.props.properties.properties.find(prty => propertyId === prty._id) : null
      this.setState({ property: propertyOld })
    }
    if (this.props.errors !== prevProps.errors) {
      this.setState({
        errors: this.props.errors
      })
    }
  }

  render () {
    const { errors } = this.state
    const { classes } = this.props
    const propertyId = this.props.location.pathname.replace('/edit/', '')
    const property = this.props.properties.properties.length > 0 ? this.props.properties.properties.find(prty => propertyId === prty._id) : null
    return (
        <Container fixed>
            <Grid
              container
              direction="row"
              spacing={1}
              style={{ minHeight: '70vh' }}>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  Edit property &mdash; {property ? property.location.street : 'Unavailable'}
                </Typography>
                <Divider width="100%" />
              </Grid>
              <Grid item xs={12}>
              { property && this.state.property
                ? <div>
                <CssBaseline />
                <div className={classes.paper}>
                    <form className={classes.form} onSubmit={this.onSubmit} noValidate>
                      <Grid container spacing={1}>
                      <Grid item xs={12}>
                            <Typography component="h1" variant="h5" gutterBottom>
                              Property
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                margin="dense"
                                autoFocus
                                fullWidth
                                id="locationStreet"
                                label="Street"
                                name="location.street"
                                onChange={this.onChange}
                                value={this.state.property.location.street || ''}
                                error={!!errors.locationStreet}
                                helperText={errors.locationStreet}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                id="locationCity"
                                label="City"
                                name="location.city"
                                onChange={this.onChange}
                                value={this.state.property.location.city || ''}
                                error={!!errors.locationCity}
                                helperText={errors.locationCity}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                margin="dense"
                                id="locationState"
                                label="State"
                                name="location.state"
                                onChange={this.onChange}
                                value={this.state.property.location.state || ''}
                                error={!!errors.locationState}
                                helperText={errors.locationState}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                id="locationZipCode"
                                label="Zipcode"
                                name="location.zipCode"
                                onChange={this.onChange}
                                value={this.state.property.location.zipCode || ''}
                                error={!!errors.locationZipCode}
                                helperText={errors.locationZipCode}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="season"
                                label="Season"
                                name="season"
                                onChange={this.onChange}
                                value={this.state.property.season || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="price"
                                label="Price"
                                name="price"
                                onChange={this.onChange}
                                value={this.state.property.price || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                              <KeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                format="MM/DD/YYYY"
                                margin="dense"
                                fullWidth
                                id="contractStartDate"
                                name="contractStartDate"
                                label="Contract Start Date"
                                value={this.state.property.contractStartDate || null}
                                onChange={date => this.onChangeDate(date, 'contractStartDate')}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                              <KeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                format="MM/DD/YYYY"
                                margin="dense"
                                fullWidth
                                id="contractEndDate"
                                name="contractEndDate"
                                label="Contract End Date"
                                value={this.state.property.contractEndDate || null}
                                onChange={date => this.onChangeDate(date, 'contractEndDate')}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography component="h1" variant="h5" gutterBottom>
                              Owner
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="owner.firstName"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="owner.firstName"
                                label="First Name"
                                onChange={this.onChange}
                                value={this.state.property.owner.firstName || ''}
                                error={errors.firstName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="owner.lastName"
                                label="Last Name"
                                name="owner.lastName"
                                onChange={this.onChange}
                                value={this.state.property.owner.lastName || ''}
                                error={errors.lastName}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="owner.email"
                                label="Email Address"
                                name="owner.email"
                                onChange={this.onChange}
                                value={this.state.property.owner.email || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="ownerStreet"
                                label="Street"
                                name="owner.address.street"
                                onChange={this.onChange}
                                value={this.state.property.owner.address.street || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                id="ownerCity"
                                label="City"
                                name="owner.address.city"
                                onChange={this.onChange}
                                value={this.state.property.owner.address.city || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                id="ownerState"
                                label="State"
                                name="owner.address.state"
                                onChange={this.onChange}
                                value={this.state.property.owner.address.state || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="ownerZipCode"
                                label="Zipcode"
                                name="owner.address.zipCode"
                                onChange={this.onChange}
                                value={this.state.property.owner.address.zipCode || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="ownerCellPhone"
                                label="Cell Phone"
                                name="owner.cellPhone"
                                onChange={this.onChange}
                                value={this.state.property.owner.cellPhone || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                id="ownerHomePhone"
                                label="Home Phone"
                                name="owner.homePhone"
                                onChange={this.onChange}
                                value={this.state.property.owner.homePhone || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}></Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                id="ownerOfficePhone"
                                label="Office Phone"
                                name="owner.officePhone"
                                onChange={this.onChange}
                                value={this.state.property.owner.officePhone || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                id="ownerOtherPhone"
                                label="Other Phone"
                                name="owner.otherPhone"
                                onChange={this.onChange}
                                value={this.state.property.owner.otherPhone || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}></Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                id="ownerAlarmCode"
                                label="Alarm Code"
                                name="owner.alarmCode"
                                onChange={this.onChange}
                                value={this.state.property.owner.alarmCode || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                multiline
                                rows={3}
                                rowsMax={6}
                                id="ownerAdditional"
                                label="Additional"
                                name="owner.additional"
                                onChange={this.onChange}
                                value={this.state.property.owner.additional || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography component="h1" variant="h5" gutterBottom>
                              Representative to Notify
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="ownerRepToNotify"
                                margin="dense"
                                label="Name"
                                name="owner.repToNotify"
                                onChange={this.onChange}
                                value={this.state.property.owner.repToNotify || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}></Grid>
                          <Grid item xs={12} sm={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="ownerRepStreet"
                                margin="dense"
                                label="Street"
                                name="owner.repAddress.street"
                                onChange={this.onChange}
                                value={this.state.property.owner.repAddress.street || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="ownerRepCity"
                                margin="dense"
                                label="City"
                                name="owner.repAddress.city"
                                onChange={this.onChange}
                                value={this.state.property.owner.repAddress.city || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                id="ownerRepState"
                                label="State"
                                name="owner.repAddress.state"
                                onChange={this.onChange}
                                value={this.state.property.owner.repAddress.state || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="ownerownerRepZipCode"
                                margin="dense"
                                label="Zipcode"
                                name="owner.repAddress.zipCode"
                                onChange={this.onChange}
                                value={this.state.property.owner.repAddress.zipCode || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="ownerRepPhone"
                                label="Phone"
                                margin="dense"
                                name="owner.repPhone"
                                onChange={this.onChange}
                                value={this.state.property.owner.repPhone || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                id="ownerRepSecondPhone"
                                label="Alternate Phone"
                                name="owner.repSecondPhone"
                                onChange={this.onChange}
                                value={this.state.property.owner.repSecondPhone || ''}
                                error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography component="h1" variant="h5" gutterBottom>
                              Services
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesIrrigationContact"
                                label="Irrigation Company"
                                name="services.irrigation.contact"
                                onChange={this.onChange}
                                value={this.state.property.services.irrigation.contact || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesIrrigationPhone"
                                label="Irrigation Phone"
                                name="services.irrigation.phone"
                                onChange={this.onChange}
                                value={this.state.property.services.irrigation.phone || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesPlumberContact"
                                label="Plumbing Company"
                                name="services.plumber.contact"
                                onChange={this.onChange}
                                value={this.state.property.services.plumber.contact || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesPlumberPhone"
                                label="Plumbing Phone"
                                name="services.plumber.phone"
                                onChange={this.onChange}
                                value={this.state.property.services.plumber.phone || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesElectricianContact"
                                label="Electrician Company"
                                name="services.electrician.contact"
                                onChange={this.onChange}
                                value={this.state.property.services.electrician.contact || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesElectricianPhone"
                                label="Electrician Phone"
                                name="services.electrician.phone"
                                onChange={this.onChange}
                                value={this.state.property.services.electrician.phone || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesCarpenterContact"
                                label="Carpentry Company"
                                name="services.carpenter.contact"
                                onChange={this.onChange}
                                value={this.state.property.services.carpenter.contact || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesCarpenterPhone"
                                label="Carpentry Phone"
                                name="services.carpenter.phone"
                                onChange={this.onChange}
                                value={this.state.property.services.carpenter.phone || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesApplianceContact"
                                label="Appliance Company"
                                name="services.appliance.contact"
                                onChange={this.onChange}
                                value={this.state.property.services.appliance.contact || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesAppliancePhone"
                                label="Appliance Phone"
                                name="services.appliance.phone"
                                onChange={this.onChange}
                                value={this.state.property.services.appliance.phone || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesFurnaceContact"
                                label="Furnace/HVAC Company"
                                name="services.furnace.contact"
                                onChange={this.onChange}
                                value={this.state.property.services.furnace.contact || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesFurnacePhone"
                                label="Furnace/HVAC Phone"
                                name="services.furnace.phone"
                                onChange={this.onChange}
                                value={this.state.property.services.furnace.phone || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesCleanerContact"
                                label="Cleaning Company"
                                name="services.cleaner.contact"
                                onChange={this.onChange}
                                value={this.state.property.services.cleaner.contact || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesCleanerPhone"
                                label="Cleaning Phone"
                                name="services.cleaner.phone"
                                onChange={this.onChange}
                                value={this.state.property.services.cleaner.phone || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesBoatsAndDocksContact"
                                label="Boats/Docks Company"
                                name="services.boatsAndDocks.contact"
                                onChange={this.onChange}
                                value={this.state.property.services.boatsAndDocks.contact || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="servicesBoatsAndDocksPhone"
                                label="Boats/Docks Phone"
                                name="services.boatsAndDocks.phone"
                                onChange={this.onChange}
                                value={this.state.property.services.boatsAndDocks.phone || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography component="h1" variant="h5" gutterBottom>
                              Special
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <FormLabel component="legend">Select all that apply</FormLabel>
                            <FormGroup row>
                              <FormControlLabel
                                control={<Checkbox checked={this.state.property.special.outsideShower || false}
                                name="special.outsideShower"
                                id="specialOutsideShower"
                                onChange={this.onChange} />}
                                label="Outside Shower"
                              />
                              <FormControlLabel
                                control={<Checkbox checked={this.state.property.special.outsideFaucet || false}
                                name="special.outsideFaucet"
                                id="specialOutsideFaucet"
                                onChange={this.onChange} />}
                                label="Outside Faucet"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox checked={this.state.property.special.outsideSpa || false}
                                  name="special.outsideSpa"
                                  id="specialOutsideSpa"
                                  onChange={this.onChange} />
                                }
                                label="Outside Spa"
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                id="specialOther"
                                label="Other"
                                name="special.other"
                                onChange={this.onChange}
                                value={this.state.property.special.other || ''}
                                error={errors.companyName}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} justify="flex-start">
                          <Grid item xs={12} s={6} lg={3}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Save property
                            </Button>
                          </Grid>
                        </Grid>
                        <Fab aria-label="Save" style={{ position: 'fixed', right: 50, bottom: 50 }} color="primary" type="submit">
                          <SaveIcon />
                        </Fab>
                      </form>
                  </div>
                </div>
                : <div></div> }
              </Grid>
            </Grid>
        </Container>
    )
  }
}
EditView.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  updateExistingProperty: PropTypes.func.isRequired,
  getProperties: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  properties: state.properties,
  errors: state.errors
})
export default connect(
  mapStateToProps,
  { logoutUser, updateExistingProperty, getProperties }
)(withStyles(styles)(EditView))
