import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import Tiers from './Tiers'
import {
  Container,
  Typography,
  Grid
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
})

class Membership extends Component {
  constructor (props) {
    super(props)
    this.state = {
      errors: {}
    }
  }

  render () {
    return (
      <Container component="main" maxWidth="lg" spacing={0}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Membership | CaretakerDB</title>
          <link rel="canonical" href="https://caretakerdb.com/membership" />
        </Helmet>
        <Grid container spacing={5} alignItems="flex-end">
          <Grid item xs={12}>
            <Typography variant="h2" component="h2">Membership</Typography>
          </Grid>
          <Tiers history={this.props.history} />
        </Grid>
      </Container>
    )
  }
}
Membership.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth
})
export default connect(
  mapStateToProps
)(withStyles(styles)(Membership))
