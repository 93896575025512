import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import PrintIcon from '@material-ui/icons/Print'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import ReactToPrint from 'react-to-print'

class PropertyOptions extends Component {
  render () {
    const { property, handleCloseDelete, handleClose, menuOpen, sendContract, printRef } = this.props
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={menuOpen}>
        <DialogTitle id="simple-dialog-title">
          {property.location.street} Options
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <List>
          <ListItem button onClick={sendContract}>
            <ListItemAvatar>
              <Avatar>
                <SendIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Send Contract" />
          </ListItem>
          <ListItem button to={`/edit/${property._id}`} component={Link}>
            <ListItemAvatar>
              <Avatar>
                <EditIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Edit" />
          </ListItem>
          <ListItem button to={`/property/${property._id}`} component={Link}>
            <ListItemAvatar>
              <Avatar>
                <VisibilityIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="View" />
          </ListItem>
          <ReactToPrint
            pageStyle="padding: 80px;"
            trigger={() =>
            <ListItem button>
              <ListItemAvatar>
                <Avatar>
                  <PrintIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Print" />
            </ListItem>
            }
            content={() => printRef.current}
          />
          {handleCloseDelete
            ? <ListItem button onClick={handleCloseDelete}>
            <ListItemAvatar>
              <Avatar>
                <DeleteIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Delete" />
          </ListItem>
            : <ListItem button disabled>
            <ListItemAvatar>
              <Avatar>
                <DeleteIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Delete (coming soon)" />
          </ListItem>
          }
        </List>
      </Dialog>
    )
  }
}
PropertyOptions.propTypes = {
  loading: PropTypes.bool,
  property: PropTypes.object.isRequired,
  handleCloseDelete: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  sendContract: PropTypes.func.isRequired,
  printRef: PropTypes.any
}
export default PropertyOptions
