import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import Container from '@material-ui/core/Container'

const styles = theme => ({

})

class Forgot extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <Container maxWidth="lg">
        <Typography variant="h3" color="secondary">Forgot password?</Typography>
        <Typography variant="body1" color="textSecondary">Coming soon!</Typography>
      </Container>
    )
  }
}

Forgot.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})
export default connect(
  mapStateToProps
)(withRouter(withStyles(styles)(Forgot)))
