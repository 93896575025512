export const GET_ERRORS = 'GET_ERRORS'
export const USER_LOADING = 'USER_LOADING'
export const LOADING = 'LOADING'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_PROPERTIES = 'SET_PROPERTIES'
export const ADD_PROPERTY = 'ADD_PROPERTY'
export const DELETE_PROPERTY = 'DELETE_PROPERTY'
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY'
export const DELETE_PROPERTY_EVENT = 'DELETE_PROPERTY_EVENT'
export const SEND_CONTRACT_SUCCESS = 'SEND_CONTRACT_SUCCESS'
export const DISABLE_ALERT = 'DISABLE_ALERT'
