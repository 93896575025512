import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'

import { resetPassword } from '../../actions/authActions'

import { withStyles } from '@material-ui/core/styles'
import {
  Typography,
  Paper,
  Chip,
  Grid,
  TextField,
  Container,
  Button
} from '@material-ui/core'

const styles = theme => ({
  paper: {
    padding: theme.spacing(3)
  }
})

class UniqueReset extends Component {
  constructor (props) {
    super(props)
    this.state = {
      reset: null,
      resetId: this.props.location.pathname.replace('/resets/', ''),
      password: '',
      password2: '',
      errors: {}
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    const resetId = this.props.location.pathname.replace('/resets/', '')

    axios
      .get('/api/users/resets/' + resetId)
      .then(res => {
        this.setState({ reset: res.data })
      })
      .catch(err => console.log('Error, get reset failed with: ' + err))
  }

  componentDidUpdate (prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({
        errors: this.props.errors
      })
    }
  }

  onChange (e) {
    this.setState({ [e.target.id]: e.target.value })
  }

  onSubmit (e) {
    e.preventDefault()
    const newPass = {
      password: this.state.password,
      password2: this.state.password2,
      resetId: this.state.resetId,
      changeTime: Date.now()
    }

    this.props.resetPassword(newPass, this.props.history)
  };

  render () {
    const { errors } = this.state
    const { classes } = this.props
    return (
      <Container maxWidth="lg">
        <Paper className={classes.paper}>
          <Typography variant="h3" color="secondary">Password Reset</Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>Automatically change your password!</Typography>
          {this.state.reset
            ? <div>
              <Typography variant="body1" color="secondary" gutterBottom>Please be sure to choose a strong password of a length longer than <b>8</b> characters.</Typography>
              <form className={classes.form} onSubmit={this.onSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                      autoComplete="fname"
                      name="password"
                      variant="outlined"
                      required
                      fullWidth
                      id="password"
                      label="New Password"
                      type="password"
                      autoFocus
                      onChange={this.onChange}
                      value={this.state.password}
                      error={!!errors.password}
                      helperText={errors.password}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="password2"
                      label="Confirm password"
                      name="password2"
                      type="password"
                      autoComplete="lname"
                      onChange={this.onChange}
                      value={this.state.password2}
                      error={!!errors.password2}
                      helperText={errors.password2}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} justify="flex-start">
                <Grid item xs={12}>
                  <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                  >
                      Update password
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
            : <Chip
              label="No password reset request found"
              color="secondary"
            />}
        </Paper>
      </Container>
    )
  }
}

UniqueReset.propTypes = {
  auth: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})
export default connect(
  mapStateToProps,
  { resetPassword }
)(withRouter(withStyles(styles)(UniqueReset)))
