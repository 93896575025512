import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Paper,
  Container,
  Typography,
  Grid,
  Fab
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
})

class ResetSuccess extends Component {
  constructor (props) {
    super(props)
    this.state = {
      clientSecret: '',
      errors: {}
    }
  }

  render () {
    const { classes } = this.props
    return (
      <Container component="main" maxWidth="lg" spacing={0}>
        <Paper className={classes.paper}>
          <Grid container spacing={5} alignItems="flex-end">
            <Grid item xs={12}>
              <Typography variant="h2" component="h2">Password Reset Success</Typography>
              <Typography variant="body2" component="p" color="textSecondary">Your password was reset successful!</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" color="secondary">Please keep a lookout for an email from <b>pwd@caretakerdb.com</b> with a unique password reset link <em>in just a few minutes</em>. Once you receive the email, please follow the steps listed there to reset and login to your account.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" color="secondary">Please feel free to contact us with any password reset issues/questions you have!</Typography>
            </Grid>
            <Grid item xs={12}>
              <Fab component={Link} to="/" color="primary">
                <ArrowBackIcon />
              </Fab>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    )
  }
}
ResetSuccess.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth
})
export default connect(
  mapStateToProps
)(withStyles(styles)(ResetSuccess))
