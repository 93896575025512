import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import moment from 'moment'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { deleteExistingProperty, getProperties, sendPropertyContract, updateExistingPropertyRedirectHome } from '../../actions/propertyActions'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import { Helmet } from 'react-helmet'
import {
  Container,
  Grid,
  Typography,
  Fab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import TrashIcon from '@material-ui/icons/Delete'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PropertyOptions from './PropertyOptions'
import PropertyEventView from './PropertyEvent/PropertyEventView'
import PropertyEventModal from './PropertyEvent/PropertyEventModal'
import Print from './Print'

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  propertyEventGrid: {
    marginTop: theme.spacing(3)
  },
  map: {
    height: '300px',
    margin: '0 auto',
    width: '100%'
  },
  propertyInfo: {
    marginBottom: theme.spacing(2)
  },
  fabEdit: {
    position: 'fixed',
    '@media (min-width: 1px)': {
      bottom: '5px',
      right: '5px'
    },
    '@media (min-width: 600px)': {
      bottom: '20px',
      right: '20px'
    },
    '@media (min-width: 976px)': {
      bottom: '50px',
      right: '50px'
    }
  },
  fabDelete: {
    position: 'fixed',
    '@media (min-width: 1px)': {
      bottom: '5px',
      left: '245px'
    },
    '@media (min-width: 600px)': {
      bottom: '20px',
      left: '260px'
    },
    '@media (min-width: 976px)': {
      bottom: '50px',
      left: '290px'
    }
  },
  alert: {
    margin: theme.spacing(1)
  }
})

class PropertyView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hovered: false,
      opened: false,
      optionsOpen: false,
      propertyId: this.props.location.pathname.replace('/property/', ''),
      addNewEventModal: false,
      events: null
    }
    this.deleteOnClick = this.deleteOnClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleCloseDelete = this.handleCloseDelete.bind(this)
    this.openOptions = this.openOptions.bind(this)
    this.sendContract = this.sendContract.bind(this)
    this.handleCloseOptions = this.handleCloseOptions.bind(this)
    this.removeEvent = this.removeEvent.bind(this)
    this.getPropertyTotalOwed = this.getPropertyTotalOwed.bind(this)
    this.handleCloseEvent = this.handleCloseEvent.bind(this)
    this.addEvent = this.addEvent.bind(this)
  };

  handleCloseOptions () {
    this.setState({ optionsOpen: false })
  }

  sendContract () {
    this.setState({ optionsOpen: false })
    const propertyId = this.props.location.pathname.replace('/property/', '')
    this.props.sendPropertyContract(this.props.properties.properties.length > 0 ? this.props.properties.properties.find(prty => propertyId === prty._id) : null, this.props.auth.user)
  }

  openOptions () {
    this.setState({ optionsOpen: true })
  }

  deleteOnClick () {
    this.setState({ optionsOpen: false })
    this.setState({ opened: true })
  };

  handleClose () {
    this.setState({ opened: false })
  };

  handleCloseDelete () {
    this.props.deleteExistingProperty(this.state.propertyId, this.props.auth.user.id, this.props.history)
  };

  removeEvent (eventId) {
    this.setState({
      events: this.state.events.filter(event => event.eventId !== eventId)
    })
  }

  addEvent (event) {
    this.setState({
      events: this.state.events.concat(event)
    })
  }

  componentDidMount () {
    if (!this.props.properties.properties.length) {
      this.props.getProperties(this.props.auth.user.id)
    }
    axios
      .get(`/api/properties/events/${this.props.location.pathname.replace('/property/', '')}`)
      .then(res => {
        const sortedEvents = res.data.events.sort((a, b) => moment(b.eventDate).diff(moment(a.eventDate)))
        this.setState({ events: sortedEvents })
      })
      .catch(err => console.log('Error, get reset failed with: ' + err))
  };

  getNiceDate (property) {
    const tempSign = property && new Date(Date.parse(property.terms.date))
    const signedDate = property && `${tempSign.getUTCMonth() + 1}/${tempSign.getUTCDate()}/${tempSign.getUTCFullYear()}`
    return signedDate
  }

  getPropertyTotalOwed () {
    const propertyId = this.props.location.pathname.replace('/property/', '')
    const property = this.props.properties.properties.length > 0 ? this.props.properties.properties.find(prty => propertyId === prty._id) : null
    let runningTotal = property.price

    if (this.state.events) {
      for (const e in this.state.events) {
        const event = this.state.events[e]
        if (event.eventType === 1) {
          runningTotal += event.amount
        } else if (event.eventType === 0) {
          runningTotal -= event.amount
        }
      }
    } else {
      runningTotal = property.currentOwed
    }

    return runningTotal
  }

  componentWillUnmount () {
    const propertyId = this.props.location.pathname.replace('/property/', '')
    const property = this.props.properties.properties.length > 0 ? this.props.properties.properties.find(prty => propertyId === prty._id) : null

    if (this.getPropertyTotalOwed() <= 0) {
      property.paid = true
    } else {
      property.paid = false
    }

    // make sure to update the current owed for more accurate Signature Requested emails
    property.currentOwed = this.getPropertyTotalOwed()

    this.props.updateExistingPropertyRedirectHome(property, this.props.auth.user.id, this.props.history)
  }

  handleCloseEvent () {
    this.setState({ addNewEventModal: false })
  };

  render () {
    const fabEdit = {
      color: 'primary',
      icon: <MoreVertIcon />,
      label: 'More Options'
    }
    const fabDelete = {
      color: 'secondary',
      icon: <TrashIcon />,
      label: 'Delete'
    }
    const { classes } = this.props
    const propertyId = this.props.location.pathname.replace('/property/', '')
    const property = this.props.properties.properties.length > 0 ? this.props.properties.properties.find(prty => propertyId === prty._id) : null
    const position = property && property.location.lat ? [property.location.lat, property.location.lon] : [0, 0]
    const signedDate = this.getNiceDate(property)
    return (
      <Container component="main" maxWidth="lg" className={classes.conent}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Property View | CaretakerDB</title>
          <link rel="canonical" href="https://caretakerdb.com/property" />
        </Helmet>
        {property
          ? <section className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card className={classes.propertyInfo}>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Property Location
                  </Typography>
                  <Typography variant="h4" component="h4">
                    {property.location.street}
                  </Typography>
                  <Typography color="textSecondary">
                    {property.location.city}, {property.location.state} {property.location.zipCode}
                  </Typography>
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Contract notifications
                  </Typography>

                  {this.getPropertyTotalOwed() <= 0
                    ? <Alert severity="info" className={classes.alert}>No payment due</Alert>
                    : <Alert severity="error" className={classes.alert}>Payment due</Alert>}
                  <Alert severity="info" className={classes.alert}>Account balance: <b>${this.getPropertyTotalOwed()}</b></Alert>
                  <Alert severity="info" className={classes.alert}>
                  {property.contractEndDate && property.contractStartDate ? `${moment(property.contractStartDate).format('MMMM Do YYYY')} to ${moment(property.contractEndDate).format('MMMM Do YYYY')}` : 'No period data'}
                  </Alert>
                  {!property.paid
                    ? <Alert severity="error" className={classes.alert}>Contract not paid</Alert>
                    : <Alert severity="info" className={classes.alert}>Contract paid</Alert>}
                  {property.terms.signed
                    ? <Alert severity="info" className={classes.alert}>Signed by {property.terms.signed} on {signedDate}</Alert>
                    : <Alert severity="error" className={classes.alert}>Contract not signed</Alert>}
                  {moment(Date.now()).isAfter(property.contractEndDate) && <Alert severity="error" className={classes.alert}>Contract expired</Alert>}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              {property && property.location.lat
                ? <Map
                center={position}
                zoom={13}
                className={ classes.map }
                dragging={false}
                scrollWheelZoom={false}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                />
                <Marker position={position}>
                  <Popup>{property.location.street}</Popup>
                </Marker>
              </Map>
                : <div></div>}
            </Grid>
            <Grid item xs={12} md={6} height="100%">
              <Card className={classes.card}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Property Owner
                  </Typography>
                  <Typography variant="h5" component="h5">
                    {property.owner.firstName} {property.owner.lastName}
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    {property.owner.address.city}, {property.owner.address.state}
                  </Typography>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">Location</TableCell>
                        <TableCell align="right">{property.owner.address.street}, {property.owner.address.city}, {property.owner.address.state} {property.owner.address.zipCode}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Email</TableCell>
                        <TableCell align="right">{property.owner.email}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Cell Phone</TableCell>
                        <TableCell align="right">{property.owner.cellPhone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Home Phone</TableCell>
                        <TableCell align="right">{property.owner.homePhone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Office Phone</TableCell>
                        <TableCell align="right">{property.owner.officePhone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Other Phone</TableCell>
                        <TableCell align="right">{property.owner.otherPhone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Plowing</TableCell>
                        <TableCell align="right">{property.owner.plowing}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Alarm code</TableCell>
                        <TableCell align="right">{property.owner.alarmCode}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Additional</TableCell>
                        <TableCell align="right">{property.owner.additional}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} height="100%">
              <Card className={classes.card}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Representative to Notify
                  </Typography>
                  <Typography variant="h5" component="h5">
                    {property.owner.repToNotify}
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    {property.owner.repAddress.city}, {property.owner.repAddress.state}
                  </Typography>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">Location</TableCell>
                        <TableCell align="right">{property.owner.repAddress.street}, {property.owner.repAddress.city}, {property.owner.repAddress.state} {property.owner.repAddress.zipCode}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Rep Phone</TableCell>
                        <TableCell align="right">{property.owner.repPhone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Rep Second Phone</TableCell>
                        <TableCell align="right">{property.owner.repSecondPhone}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} height="100%">
              <Card className={classes.card}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Property Services
                  </Typography>
                  <Typography variant="h5" component="h5">
                    Services
                  </Typography>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell component="th" scope="row">Service</TableCell>
                        <TableCell align="right">Contact</TableCell>
                        <TableCell align="right">Phone</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">Irrigation</TableCell>
                        <TableCell align="right">{property.services.irrigation.contact}</TableCell>
                        <TableCell align="right">{property.services.irrigation.phone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Plumbing</TableCell>
                        <TableCell align="right">{property.services.plumber.contact}</TableCell>
                        <TableCell align="right">{property.services.plumber.phone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Electrician</TableCell>
                        <TableCell align="right">{property.services.electrician.contact}</TableCell>
                        <TableCell align="right">{property.services.electrician.phone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Carpentry</TableCell>
                        <TableCell align="right">{property.services.carpenter.contact}</TableCell>
                        <TableCell align="right">{property.services.carpenter.phone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Appliance</TableCell>
                        <TableCell align="right">{property.services.appliance.contact}</TableCell>
                        <TableCell align="right">{property.services.appliance.phone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Furnace</TableCell>
                        <TableCell align="right">{property.services.furnace.contact}</TableCell>
                        <TableCell align="right">{property.services.furnace.phone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Cleaner</TableCell>
                        <TableCell align="right">{property.services.cleaner.contact}</TableCell>
                        <TableCell align="right">{property.services.cleaner.phone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Boats and Docks</TableCell>
                        <TableCell align="right">{property.services.boatsAndDocks.contact}</TableCell>
                        <TableCell align="right">{property.services.boatsAndDocks.phone}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} height="100%">
              <Card className={classes.card}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Property Special
                  </Typography>
                  <Typography variant="h5" component="h5">
                    Special
                  </Typography>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">Outside Shower</TableCell>
                        <TableCell align="right">{property.special.outsideShower ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Outside Faucet</TableCell>
                        <TableCell align="right">{property.special.outsideFaucet ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Outside Spa</TableCell>
                        <TableCell align="right">{property.special.outsideSpa ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Other</TableCell>
                        <TableCell align="right">{property.special.other}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} height="100%">
              <Card className={classes.card}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Property Contract
                  </Typography>
                  <Typography variant="h5" component="h5">
                    Contract
                  </Typography>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">Dated</TableCell>
                        <TableCell align="right">{property.terms.date ? signedDate : 'Not signed yet'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">Signed by</TableCell>
                        <TableCell align="right">{property.terms.signed ? property.terms.signed : 'Not signed yet'}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Fab aria-label={fabEdit.label} className={classes.fabEdit} color={fabEdit.color} onClick={this.openOptions}>
            {fabEdit.icon}
          </Fab>
          <Fab aria-label={fabDelete.label} className={classes.fabDelete} color={fabDelete.color} onClick={this.deleteOnClick}>
            {fabDelete.icon}
          </Fab>
          <PropertyOptions printRef={this.componentRef} menuOpen={this.state.optionsOpen} handleClose={this.handleCloseOptions} handleCloseDelete={this.deleteOnClick} property={property} sendContract={this.sendContract} />
          <Dialog
            open={this.state.opened}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{'Delete this property?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete {property.location.street}, {property.location.city}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleCloseDelete} color="secondary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.propertyEventGrid}
            spacing={2}
          >
            <Grid item xs={12} md={12}>
              <Card className={classes.propertyInfo}>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Invoices, payments and notes
                  </Typography>
                  <Typography variant="h4" component="h4" gutterBottom>
                    Property History
                  </Typography>
                  <Button variant="outlined" color="primary" onClick={() => this.setState({ addNewEventModal: true })}>
                    Add event
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <PropertyEventModal opened={this.state.addNewEventModal} addPropertyEventLocal={this.addEvent} propertyId={propertyId} handleCloseEvent={this.handleCloseEvent} />
            {this.state.events
              ? (this.state.events.length > 0 &&
                this.state.events.map((event, i) => {
                  return (
                    <PropertyEventView event={event} removeEvent={this.removeEvent} key={i} />)
                }))
              : <Grid item xs={4}>
                <Card margin={2} raised={true}>
                  <CardHeader
                    avatar={
                        <Typography color="secondary" align="center">
                          <HomeWorkIcon fontSize="large" />
                        </Typography>
                    }
                    action={
                      <IconButton aria-label="settings" onClick={this.openOptions}>
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title="Example Event"
                    subheader="Invoice"
                  />
                  <Divider />
                  <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                      Example text | example cost
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            }
          </Grid>
          <div style={{ display: 'none' }}>
            <Print {...this.props} forwardedRef={el => (this.componentRef = el)} property={property} />
          </div>
        </section>
          : <Grid
          container
          direction="column"
          spacing={3}>
          <Grid item>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} height="100%">
                <Typography variant="h5" component="h5">
                    This property is unavailable.
                </Typography>
                <Typography component="p">
                    Welcome to the property view page where you can see all the information for a specific property!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        }
      </Container>
    )
  }
}
PropertyView.propTypes = {
  auth: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  sendPropertyContract: PropTypes.func.isRequired,
  updateExistingPropertyRedirectHome: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  deleteExistingProperty: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  getProperties: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  properties: state.properties
})
export default connect(
  mapStateToProps,
  {
    deleteExistingProperty,
    getProperties,
    sendPropertyContract,
    updateExistingPropertyRedirectHome
  }
)(withStyles(styles)(PropertyView))
