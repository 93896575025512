import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { disableContractAlert } from '../../actions/propertyActions'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

class SendContractSuccess extends Component {
  constructor (props) {
    super(props)
    this.state = {
      errors: {}
    }
  }

  componentDidUpdate () {
    if (this.props.loading.sendContractIsNew) {
      window.setTimeout(this.props.disableContractAlert, 8 * 1000)
    }
  }

  render () {
    const { loading } = this.props
    return (
      <div>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={loading.sendContractIsNew && loading.sendContractSuccess}>
          <Alert severity="success">
            Contract signature request sent!
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={loading.sendContractIsNew && !loading.sendContractSuccess}>
          <Alert severity="error">
            Contract signature request failed to send. Please contact us!
          </Alert>
        </Snackbar>
      </div>
    )
  }
}
SendContractSuccess.propTypes = {
  auth: PropTypes.object.isRequired,
  loading: PropTypes.object.isRequired,
  disableContractAlert: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  loading: state.loading
})
export default connect(
  mapStateToProps,
  { disableContractAlert }
)(withStyles(styles)(SendContractSuccess))
