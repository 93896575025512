import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Backdrop,
  CircularProgress
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

class UpdateLoader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      errors: {}
    }
  }

  render () {
    const { loading, classes } = this.props
    return (
      <Backdrop open={loading.loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }
}
UpdateLoader.propTypes = {
  auth: PropTypes.object.isRequired,
  loading: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  loading: state.loading
})
export default connect(
  mapStateToProps
)(withStyles(styles)(UpdateLoader))
