import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'

import { registerUser } from '../../actions/authActions'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import Container from '@material-ui/core/Container'

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
})

const CAPTCHA_KEY_PUBLIC = '6LeyzN4UAAAAAMrRM91WnAv00TM5jDH3nyJEd-nD'

class Register extends Component {
  constructor (props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      password: '',
      password2: '',
      captcha: '',
      errors: {}
    }
    this._reCaptchaRef = React.createRef()
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({
        errors: this.props.errors
      })
    }
  }

  componentDidMount () {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/dashboard')
    }
  }

  onChange (e) {
    this.setState({ [e.target.id]: e.target.value })
  }

  handleChange (val) {
    this.setState({ captcha: val })
  }

  onSubmit (e) {
    e.preventDefault()
    const newUser = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      companyName: this.state.companyName,
      password: this.state.password,
      password2: this.state.password2,
      captcha: this.state.captcha
    }
    this.props.registerUser(newUser, this.props.history)
  };

  render () {
    const { errors } = this.state
    const { classes } = this.props
    return (
        <Container component="main" maxWidth="xs">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Register | CaretakerDB</title>
            <link rel="canonical" href="https://caretakerdb.com/register" />
            <meta name="description" content="CaretakerDB register page, enter your information to sign up for a free account." />
          </Helmet>
            <CssBaseline />
            <Paper className={classes.paper} elevation={3}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" gutterBottom>
                  Sign up
                </Typography>
                <form className={classes.form} onSubmit={this.onSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="fname"
                        name="firstName"
                        variant="outlined"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        autoFocus
                        onChange={this.onChange}
                        value={this.state.firstName}
                        error={!!errors.firstName}
                        helperText={errors.firstName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="lname"
                        onChange={this.onChange}
                        value={this.state.lastName}
                        error={!!errors.lastName}
                        helperText={errors.lastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        onChange={this.onChange}
                        value={this.state.email}
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="companyName"
                        label="Company Name"
                        name="companyName"
                        autoComplete="companyName"
                        onChange={this.onChange}
                        value={this.state.companyName}
                        error={!!errors.companyName}
                        helperText={errors.companyName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={this.onChange}
                        value={this.state.password}
                        error={!!errors.password}
                        helperText={errors.password}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password2"
                        label="Confirm Password"
                        type="password"
                        id="password2"
                        autoComplete="current-password"
                        onChange={this.onChange}
                        value={this.state.password2}
                        error={!!errors.password2}
                        helperText={errors.password2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReCAPTCHA
                      ref={this._reCaptchaRef}
                      id="captcha"
                      sitekey={CAPTCHA_KEY_PUBLIC}
                      onChange={this.handleChange}
                    />
                    <Typography variant="body2" color="textSecondary" style={{ color: 'red' }}>
                    {errors.captcha && errors.captcha}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} justify="flex-start">
                  <Grid item xs={12}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign Up
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2} justify="flex-end">
                  <Grid item>
                    <Link to="/login" variant="body2">
                        Already have an account? Sign in
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/contact" variant="body2">
                        Need a registration key?
                    </Link>
                  </Grid>
                </Grid>
                </form>
            </Paper>
        </Container>
    )
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})
export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(withStyles(styles)(Register)))
