import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import {
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Divider,
  IconButton,
  Typography,
  Grid
} from '@material-ui/core'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Alert from '@material-ui/lab/Alert'
import Skeleton from '@material-ui/lab/Skeleton'
import { withStyles } from '@material-ui/core/styles'

import PropertyOptions from './PropertyOptions'
import Print from './Print'
import { sendPropertyContract } from '../../actions/propertyActions'

const styles = theme => ({
  alert: {
    marginBottom: theme.spacing(3)
  }
})

class Property extends Component {
  constructor (props) {
    super(props)
    this.state = {
      signedDate: new Date(Date.parse(props.property.terms.date)),
      hovered: false,
      optionsOpen: false,
      alertOpen: false,
      errors: {}
    }
    this.openOptions = this.openOptions.bind(this)
    this.sendContract = this.sendContract.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)

    this.componentRef = React.createRef()
  };

  handleClose () {
    this.setState({ optionsOpen: false })
  }

  handleCloseAlert () {
    this.setState({ alertOpen: false })
  }

  sendContract () {
    this.setState({ optionsOpen: false, alertOpen: true })
    this.props.sendPropertyContract(this.props.property, this.props.auth.user)
  }

  openOptions () {
    this.setState({ optionsOpen: true })
  }

  render () {
    const { loading = false } = this.props
    const { property, classes } = this.props
    const signedDate = `${this.state.signedDate.getUTCMonth() + 1}/${this.state.signedDate.getUTCDate()}/${this.state.signedDate.getUTCFullYear()}`
    return (
      <Card margin={2} raised={true}>
        <CardHeader
          avatar={
            loading
              ? (<Skeleton variant="circle" width={40} height={40} />)
              : (<Typography color="secondary" align="center">
                <HomeWorkIcon fontSize="large" />
              </Typography>)
          }
          action={
            <IconButton aria-label="settings" onClick={this.openOptions}>
              <MoreVertIcon />
            </IconButton>
          }
          title={loading ? <Skeleton height={6} width="80%" /> : `${property.owner.firstName} ${property.owner.lastName}`}
          subheader={loading ? <Skeleton height={6} width="40%" /> : property.owner.address.city ? `${property.owner.address.city}, ${property.owner.address.state}` : 'No location set'}
        />
        <Divider />
        <CardActionArea to={{
          pathname: property ? ('/property/' + property._id) : '/dashboard'
        }} component={Link}>
          {loading
            ? (<CardContent>
                <Skeleton height={6} />
                <Skeleton height={6} width="80%" />
              </CardContent>)
            : (<CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                  <Typography variant="h5" component="h5">
                    {`${property.location.street}`}
                    </Typography>
                    {property.location.city &&
                      <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                        {
                          `${property.location.city}`
                        }
                      </Typography>
                    }
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {!property.paid
                      ? <Alert severity="error" className={classes.alert}>Contract not paid</Alert>
                      : <Alert severity="success" className={classes.alert}>Contract paid</Alert>}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {property.terms.signed
                      ? <Alert severity="success" className={classes.alert}>Signed by {property.terms.signed} on {signedDate}</Alert>
                      : <Alert severity="warning" className={classes.alert}>Contract not signed</Alert>}
                  </Grid>
                  {moment(Date.now()).isAfter(property.contractEndDate) && (
                    <Grid item xs={12} md={3}>
                      <Alert severity="warning" className={classes.alert}>Contract expired</Alert>
                    </Grid>
                  )}
                </Grid>
            </CardContent>)}
        </CardActionArea>
        <div style={{ display: 'none' }}>
          <Print {...this.props} property={property} companyName={this.props.auth.user.companyName} ref={this.componentRef} />
        </div>
        <PropertyOptions printRef={this.componentRef} menuOpen={this.state.optionsOpen} handleClose={this.handleClose} classes={classes} property={property} sendContract={this.sendContract} />
      </Card>
    )
  }
}
Property.propTypes = {
  sendPropertyContract: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  property: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  properties: state.properties
})
export default connect(
  mapStateToProps,
  { sendPropertyContract }
)(withStyles(styles)(Property))
